export function getOrigin(windowObj) {
  // for IE - set window.location.origin if browser does not have window.location.origin
  if (!windowObj.location.origin) {
    windowObj.location.origin = `${windowObj.location.protocol}//${windowObj.location.hostname}${
      windowObj.location.port ? ':' + windowObj.location.port : ''
    }`
  }
  return windowObj.location.origin
}

const envConfig = {
  development: {
    apiURL: 'https://apply-service.softserve.trdev.co/',
    bffURL: 'https://dev-applicant-bff-external-api.trdev.co',
    clientURL: 'https://qa-applicant-sr.jobappdemo.com',
    cas: {
      login: 'https://qa-cas.jobappdemo.com/login?',
      logout: 'https://forms.jobappdemo.com/',
    },
    heimdallUrl: '',
    manageUrl: 'https://forms.jobappdemo.com/manage',
    marketingAssetsURL: 'https://marketing-material-service.softserve.trdev.co/TEST/',
    cloudFrontURL: 'https://marketing-assets.softserve.trdev.co/',
    elasticSearchURL: 'https://apply-service.softserve.trdev.co/proxy-es',
    ATSApply: 'https://qa3.jobappdemo.com',
    applyUrl: 'https://dev-applicant.trdev.co',
    domain: '.trdev.co',
    cognitoIdentityPoolId: 'us-west-2:61224fd7-8e09-4f96-8c9b-2bbe434af1fa',
    pinpointProjectId: '31d680f5dcb3458b9726e524f3e9b853',
    chatApplyURL: 'https://dev-chatapply-api-internal-alb.trdev.co',
    imagesURL: 'https://dev-images.trdev.co',
  },
  qa: {
    apiURLDomain: 'https://qa-kong.jobappdemo.com/',
    apiURL: 'https://qa-kong.jobappdemo.com/apply',
    bffURL: 'https://qa-applicant-bff-external-api.jobappdemo.com',
    clientURL: 'https://qa-applicant-sr.jobappdemo.com',
    cas: {
      login: 'https://forms.jobappdemo.com',
      logout: 'https://qa-login.jobappdemo.com/logout',
    },
    heimdallUrl: 'service=https://qa-api.jobappdemo.com/auth/apply-ui',
    manageUrl: 'https://forms.jobappdemo.com/manage',
    marketingAssetsURL: 'https://qa-marketing-material-service.jobappdemo.com/',
    cloudFrontURL: 'https://qa-marketing-assets.jobappdemo.com/',
    elasticSearchURL: 'https://qa-kong.jobappdemo.com/apply/proxy-es',
    ATSApply: 'https://qa-acf.jobappdemo.com',
    applyUrl: 'https://qa-applicant.jobappdemo.com',
    domain: '.jobappdemo.com',
    cognitoIdentityPoolId: 'us-west-2:bf17953e-46f8-4942-8715-e45c52119938',
    pinpointProjectId: 'a69a66f319524838ab017983f5e769d2',
    pixelTrackingURL: 'https://qa-pixel-tracking-api-external-api.jobappdemo.com',
    chatApplyURL: 'https://qa-chatapply-api-external-api.jobappdemo.com',
    imagesURL: 'https://qa-images.jobappdemo.com',
  },
  uat: {
    apiURLDomain: 'https://uat-kong.jobappdemo.com/',
    apiURL: 'https://uat-kong.jobappdemo.com/apply',
    bffURL: 'https://uat-applicant-bff-external-api.jobappdemo.com',
    clientURL: 'https://uat-applicant-sr.jobappdemo.com',
    cas: {
      login: 'https://uat-login.jobappdemo.com/login?',
      logout: 'https://uat-login.jobappdemo.com/',
    },
    heimdallUrl: 'service=https://uat-api.jobappdemo.com/auth/forms-ui',
    manageUrl: 'https://uat-manage.jobappdemo.com/manage',
    marketingAssetsURL: 'https://uat-kong.jobappdemo.com/apply/marketing-materials/',
    cloudFrontURL: 'https://uat-marketing-assets.jobappdemo.com/',
    elasticSearchURL: 'https://uat-kong.jobappdemo.com/apply/proxy-es',
    ATSApply: 'https://uat-acf.jobappdemo.com',
    applyUrl: 'https://uat-applicant.jobappdemo.com',
    domain: '.jobappdemo.com',
    cognitoIdentityPoolId: 'us-west-2:d1e3203d-f725-4017-829b-6bd13e46cc9e',
    pinpointProjectId: 'a56a06a12d4a43ddba9b419236d06e10',
    pixelTrackingURL: 'https://uat-pixel-tracking-api-external-api.jobappdemo.com',
    chatApplyURL: 'https://uat-chatapply-api-external-api.jobappdemo.com',
    imagesURL: 'https://uat-images.jobappdemo.com',
  },
  production: {
    apiURLDomain: 'https://prod-kong.internal.talentreef.com/',
    apiURL: 'https://prod-kong.internal.talentreef.com/apply',
    bffURL: 'https://prod-applicant-bff-external-api.jobappnetwork.com',
    clientURL: 'https://apply.jobappnetwork.com',
    cas: {
      login: 'https://login.jobappnetwork.com/login?',
      logout: 'https://secure.jobappnetwork.com/',
    },
    heimdallUrl: 'service=https://api.jobappnetwork.com/auth/forms-ui',
    manageUrl: 'https://secure.jobappnetwork.com/manage',
    marketingAssetsURL: 'https://prod-kong.internal.talentreef.com/apply/marketing-materials/',
    cloudFrontURL: 'https://marketing-assets.jobappnetwork.com/',
    elasticSearchURL: 'https://prod-kong.internal.talentreef.com/apply/proxy-es',
    ATSApply: 'https://secure.jobappnetwork.com',
    applyUrl: 'https://applicant.jobappnetwork.com',
    domain: '.jobappnetwork.com',
    cognitoIdentityPoolId: 'us-west-2:4d22fe4c-764c-429a-ac11-dbdd15797229',
    pinpointProjectId: 'f4960c39868c49c3951d5d30d56f6e39',
    pixelTrackingURL: 'https://prod-pixel-tracking-api-external-api.jobappnetwork.com',
    chatApplyURL: 'https://prod-chatapply-api-external-api.jobappnetwork.com',
    imagesURL: 'https://images.jobappnetwork.com',
  },
}

const originUrl = getOrigin(window)

const nodeENV = envConfig[process.env.REACT_APP_ENV] ? process.env.REACT_APP_ENV : 'qa'

const envAliasesMap = {
  development: 'dev',
  qa: 'qa',
  uat: 'uat',
  production: 'prod',
}

const envAlias = envAliasesMap[nodeENV]

const apiMarketingAssetsURL = `${envConfig[nodeENV].marketingAssetsURL}`
const elasticSearchURL = (elasticIndex) =>
  `${envConfig[nodeENV].elasticSearchURL}/${elasticIndex}/posting/_search`

const cloudFrontURL = `${envConfig[nodeENV].cloudFrontURL}`
const ATSApplyURL = `${envConfig[nodeENV].ATSApply}`
const applyUrl = `${envConfig[nodeENV].applyUrl}`

const apiURL = `${envConfig[nodeENV].apiURL}`
const apiURLDomain = `${envConfig[nodeENV].apiURLDomain}`
const bffURL = `${envConfig[nodeENV].bffURL}`
const clientURL = `${envConfig[nodeENV].clientURL}`
const pixelTrackingURL = `${envConfig[nodeENV].pixelTrackingURL}`
const chatApplyURL = `${envConfig[nodeENV].chatApplyURL}`
const imagesURL = `${envConfig[nodeENV].imagesURL}`

const casLogoutUrl =
  envConfig[nodeENV].cas.logout + 'manage/cfc/remote/general_remote.cfc?method=casLogout'

const casLoginUrl = envConfig[nodeENV].cas.login
const heimdallUrl = envConfig[nodeENV].heimdallUrl
const manageUrl = envConfig[nodeENV].manageUrl
const domain = envConfig[nodeENV].domain
const cognitoIdentityPoolId = envConfig[nodeENV].cognitoIdentityPoolId
const pinpointProjectId = envConfig[nodeENV].pinpointProjectId
const version = process.env.REACT_APP_VERSION

const headers = {
  accept: 'application/json',
  'Content-Type': 'application/json',
  'cache-control': 'no-store',
  pragma: 'no-cache',
}

const fetchOptions = (version) => {
  const versionOptions = ['v1', 'v2']
  if (typeof version !== 'string' || !versionOptions.includes(version))
    throw new Error('must be a string: v1 or v2')
  return {
    credentials: 'include',
    withCredentials: 'true',
    headers: {
      ...headers,
      'x-api': `forms_${version}`,
    },
  }
}

const isQa = nodeENV === 'qa' || nodeENV === 'development'

const isCustomDomain = originUrl !== clientURL

export {
  apiURL,
  apiURLDomain,
  bffURL,
  clientURL,
  originUrl,
  casLogoutUrl,
  casLoginUrl,
  elasticSearchURL,
  fetchOptions,
  heimdallUrl,
  manageUrl,
  cloudFrontURL,
  version,
  apiMarketingAssetsURL,
  isCustomDomain,
  isQa,
  ATSApplyURL,
  envAlias,
  applyUrl,
  domain,
  cognitoIdentityPoolId,
  pinpointProjectId,
  pixelTrackingURL,
  chatApplyURL,
  imagesURL,
}
