import axios from 'axios'
import { CALLAPI } from '../middleware/callAPIMiddleware'
import { apiMarketingAssetsURL } from '../../config/endpoints'

const FETCH_ASSET = 'FETCH_ASSET'
const FETCH_ASSET_SUCCESS = 'FETCH_ASSET_SUCCESS'
const FETCH_ASSET_FAIL = 'FETCH_ASSET_FAIL'
const RESET_ASSET = 'RESET_ASSET'

const initialState = {
  marketingAsset: {},
  error: null,
  loading: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_ASSET:
      return {
        ...initialState,
      }
    case FETCH_ASSET:
      return {
        ...state,
        marketingAsset: {},
        loading: true,
      }
    case FETCH_ASSET_SUCCESS:
      return {
        ...state,
        marketingAsset: action.payload,
      }
    case FETCH_ASSET_FAIL:
      return {
        ...state,
        marketingAsset: {},
        error: action.payload,
      }
    default:
      return state
  }
}

export function fetchAsset(clientId) {
  const url = `${apiMarketingAssetsURL}clients/${clientId}/assets`
  return {
    type: CALLAPI,
    types: [FETCH_ASSET, FETCH_ASSET_SUCCESS, FETCH_ASSET_FAIL],
    callAPI: () => axios.get(`${url}/assets`),
  }
}
