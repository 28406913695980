// es6 polyfills for react 16+ and other es6 features
// polyfills must be first import
import './polyfills'
import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'react-router-redux'
import store, { history } from './redux/store'
import './index.css'
import Router from './components/Routes'
import { version } from './config/endpoints'
import axiosConfig from './config/axios'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'react-intl-tel-input/dist/main.css'

//https://github.com/mui-org/material-ui/issues/8872
//https://github.com/cssinjs/react-jss#custom-setup
import { JssProvider } from 'react-jss'

import { addLocaleData } from 'react-intl'
import us from 'react-intl/locale-data/en'
import fr from 'react-intl/locale-data/fr'
import es from 'react-intl/locale-data/es'

addLocaleData([...us, ...fr, ...es])

//configure global axios
axiosConfig(store)

global.requestAnimationFrame = function (callback) {
  setTimeout(callback, 0)
}

// eslint-disable-next-line no-console
console.log('v' + version)

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <JssProvider classNamePrefix="wtf">
        <Router />
      </JssProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
)
