import React, { Component } from 'react'

import getFromObject from 'lodash/get'
import { FlagIcon } from 'react-flag-kit'
import { Helmet } from 'react-helmet'
import { IntlProvider } from 'react-intl'
import { connect } from 'react-redux'
import { HashLink as Link } from 'react-router-hash-link'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import { parse } from 'query-string'
import psl from 'psl'
import queryString from 'query-string'

import MenuIcon from '@material-ui/icons/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

import { Colors } from 'tr-components/Colors'
import { Loading } from 'tr-components/Loading'

import { isCustomDomain, pixelTrackingURL, chatApplyURL, clientURL } from './../config/endpoints'

import { fetchPosting, initialState as postingInitialState } from '../redux/reducers/jobPosting'
import { fetchAsset, reserveAssetKey } from '../redux/reducers/marketingAssetsCollection'
import { closeSnackbar } from '../redux/reducers/snackbarsManager'
import {
  initialState,
  fetchTemplateByURL,
  fetchTemplateByClientId,
  setTemplateType,
  fetchTemplateByPosting,
  fetchJobDescriptionPage,
} from '../redux/reducers/template'
import { setAnalyticsParameters, setClientId } from '../redux/reducers/user'

import { jumpToAnchor } from '../services/anchoreJump'
import ElasticSearch from '../services/elasticSearch'
import { getAssetsFile } from '../static/marketingAssetsServices'

import en from '../translations/en.json'
import es from '../translations/es.json'
import fr from '../translations/fr.json'

import NotFound from './NotFound'
import PostingNotFound from './PostingNotFound'
import RootPage from './RootPage'
import ChooseSection from './Sections/ChooseSection'
import Snackbars from './Snackbar/Snackbars'
import SocialIcon from './SocialIcon'

const MESSAGES = {
  en: { ...en },
  fr: { ...fr },
  es: { ...es },
}
const SECTION_OFFSET = 113

const LANGUAGE_MAP = {
  US: { code: 'en', name: 'English (US)', elasticIndex: 'search-en-us', file: 'en' },
  CA: { code: 'fr-CA', name: 'Canadian French', elasticIndex: 'search-fr-ca', file: 'fr' },
  ES: { code: 'es', name: 'Spanish', elasticIndex: 'search-es-us', file: 'es' },
}

const LANGUAGE_CODE_TO_FLAG = {
  en: 'US',
  'fr-CA': 'CA',
  es: 'ES',
}

const ATSLangCodes = ['l_en', 'l_es', 'l_fr']

const Header = styled.div`
  position: fixed;
  top: 0;
  z-index: 99;
  background-color: ${(props) => props.hex || 'white'};
  width: 100%;
  height: 115px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-item: center;
  flex-direction: row;
  padding: 20px;
  box-sizing: border-box;
  ${(p) =>
    p.mobileMenu && 'transition: height 3s ease-out;'} @media screen and (max-width: 1180px) {
    overflow: hidden;
    ${(p) =>
      p.mobileMenu &&
      `
      height: auto;
      display: block;
    `};
  }
`

const Footer = styled.div`
  background-color: ${(props) => props.hex || 'white'};
  width: 100%;
  display: block;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 25px 0;
  text-align: center;

  > div {
    margin: 15px auto;
    max-width: 780px;
    overflow-wrap: break-word;
  }
`

const PrivacyPolicyContainer = styled.div`
  display: flex;
  background-color: white;
  width: 100%;
  text-align: left;
  font-size: 12px;
  color: black;
  margin: 0;
  padding: 0.2rem 0 0.2rem 1rem;
  max-width: 100% !important;
`

const Logo = styled.div`
  max-width: 300px;
  padding: 0;

  img {
    max-height: 60px;
  }
`

const Menu = styled.nav`
  font-size: ${(font) => font.size || '18'}px;
  font-family: ${(font) => font.fontFamily};
  text-transform: uppercase;
  flex-grow: 1;
  padding: 0 20px;
  a {
    color: ${(props) => props.color || Colors.lightGray};
    text-decoration: none;
    padding: 0 20px;
    cursor: pointer;
    line-height: 85px;
    font-weight: bold;
    &:hover {
      text-decoration: underline;
    }
  }
  @media screen and (max-width: 1180px) {
    display: none;
    ${(p) =>
      p.mobileMenu &&
      `
      margin-top: 20px;
      padding: 5px 0 20px;
      display: block;
      border-top: 2px solid ${p.color}
    `} a {
      display: block;
      line-height: 30px;
      font-size: 16px;
      transition: all 0.2s linear;
      text-align: center;

      &:hover {
        background: rgba(255, 255, 255, 0.3);
      }
    }
  }
`

const FooterLinks = styled.div`
  max-width: 780px;
  font-size: ${(font) => font.size || '18'}px;
  font-family: ${(font) => font.fontFamily};
  a {
    color: ${(font) => font.color || 'white'};
    text-decoration: none;
    padding: 0 10px;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
`

const Locales = styled.div`
  @media screen and (max-width: 1180px) {
    display: none;
  }
`

const MobileLocales = styled.div`
  display: none;
  @media screen and (max-width: 1180px) {
    display: flex;
    justify-content: space-around;
  }
`

const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Hamburger = styled.button`
  width: 40px;
  height: 40px;
  border: 1px solid ${(p) => p.color};
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  display: none;
  transition: all 0.3s linear;
  fill: ${(p) => p.color};
  &:hover {
    background: rgba(255, 255, 255, 0.3);
  }
  & .hamburger-menu-icon {
    color: ${(p) => p.color};
  }
  @media screen and (max-width: 1180px) {
    display: flex;
    ${(p) =>
      p.mobileMenu &&
      `
      position: absolute;
      top: 20px;
      right: 20px;
    `}
  }
`

const getFontFace = (font, clientId) => {
  if (font.fontId) {
    return `@import url('https://fonts.googleapis.com/css?family=${font.fontId}');`
  }
  return `
  @font-face {
    font-family: ${font.name};
    src: url(${getAssetsFile(clientId, font.id)});
  }`
}

const getNavigatorLanguage = () => {
  if (navigator.languages && navigator.languages.length) {
    return navigator.languages[0]
  } else {
    return navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en'
  }
}

export class App extends Component {
  state = {
    backgroundColor: '',
    mobileMenu: false,
    locale:
      LANGUAGE_CODE_TO_FLAG[this.props.match.params.locale] ||
      Object.keys(LANGUAGE_MAP).find((lang) =>
        getNavigatorLanguage().includes(LANGUAGE_MAP[lang].code)
      ),
    utmSources: new Map(),
  }

  getUrlHostName = (url) => {
    const a = document.createElement('a')
    a.href = url
    const parsedUrl = psl.parse(a.hostname)
    return parsedUrl.domain
  }

  async componentDidMount() {
    const {
      fetchTemplateByURL,
      fetchTemplateByClientId,
      setTemplateType,
      fetchPosting,
      setAnalyticsParameters,
      fetchJobDescriptionPage,
      match: { params },
      location: { search },
    } = this.props
    const { locale } = this.state
    let analyticsParameters = search

    const isUtmSourceInQuery = 'utm_source' in parse(analyticsParameters)
    const isUtmCampaignInQuery = 'utm_campaign' in parse(analyticsParameters)

    if (analyticsParameters) {
      setAnalyticsParameters(analyticsParameters)
    }

    if (params.postingId && params.clientId) {
      fetchPosting(
        ElasticSearch.getPostingParameters(params.postingId, params.clientId),
        this.getLocaleMap(locale).elasticIndex
      )
      setTemplateType('JobDescriptionPage')

      this.handleTrackView()
    } else if (params.jobDescriptionId) {
      fetchJobDescriptionPage(params.jobDescriptionId + analyticsParameters)
      setTemplateType('JobDescriptionPage')
    } else {
      let templateRequest
      if (params.clientId) {
        templateRequest = fetchTemplateByClientId(params.clientId)
      } else {
        templateRequest = fetchTemplateByURL(`${params.templateURL || ''}${analyticsParameters}`)
      }
      if (templateRequest !== undefined) {
        templateRequest.then((resp) => {
          if (Object.keys(resp.payload).length === 0 || resp.payload.isAxiosError) {
            console.error(
              'Error after fetching the template: Response is empty, utm source unable to set.'
            )
          } else {
            this.setState({
              utmSources: new Map(resp.payload.map((t) => [t.locale, t.utmSource])),
            })
            const utmSource = this.getUtmSource(this.getLocaleMap(locale).code)

            if (!isUtmSourceInQuery && utmSource) {
              analyticsParameters += `${search ? '&' : '?'}utm_source=${utmSource}`
            }

            if (analyticsParameters) {
              setAnalyticsParameters(analyticsParameters)
            }

            const utmSourceForChatApply = isUtmSourceInQuery
              ? queryString.parse(search).utm_source || ''
              : utmSource
            const utmCampaign = isUtmCampaignInQuery
              ? queryString.parse(search).utm_campaign || ''
              : ''

            this.handleChatApply(utmSourceForChatApply, utmCampaign)
          }
        })
      } else {
        console.error('Template Response is undefined')
      }
      setTemplateType('CareerPage')
    }
  }

  getUtmSource(localeCode) {
    let windowOpener = null
    try {
      windowOpener = window.opener ? window.opener.location.href : null
    } catch (e) {
      console.warn(e)
    }

    const linkedUri = document.referrer ? document.referrer : windowOpener
    const currentUri = window.location.href

    return linkedUri === null || linkedUri === currentUri
      ? this.state.utmSources.get(localeCode)
      : this.getUrlHostName(linkedUri)
  }

  componentDidUpdate(prevProps) {
    const {
      fetchTemplateByPosting,
      match: { params, url },
      posting,
      template: { templatesList, type, loading },
    } = this.props

    const { locale } = this.state
    const localeCode = this.getLocaleMap(locale).code

    if (!templatesList.length) {
      return
    }

    if (params !== prevProps.match.params) {
      this.handleTrackView()
    }

    const { clientId, locale: templateLocale } = this.getTemplateByLang(localeCode)

    const templateFlag = LANGUAGE_CODE_TO_FLAG[templateLocale]

    if (locale !== templateFlag && type === 'CareerPage' && !loading) {
      this.setState({
        locale: templateFlag,
      })
    }

    const analyticsParameters = this.getAnalyticsParameters(localeCode)
    const templateType = this.props.template.type

    if (url !== prevProps.match.url && templateType === 'CareerPage') {
      this.handleChatApply(
        queryString.parse(analyticsParameters).utm_source || '',
        queryString.parse(analyticsParameters).utm_campaign || ''
      )
    }

    const prevTemplateLang = prevProps.template.templatesList.find(
      (template) => template.locale === locale
    )

    if (
      prevTemplateLang &&
      clientId !==
        prevProps.template.templatesList.find((template) => template.locale === locale).clientId
    ) {
      this.props.setClientId(clientId)
    }
    if (posting !== prevProps.posting && posting.jobId) {
      const queryParameters =
        analyticsParameters + `${analyticsParameters ? '&' : '?'}brandId=${posting.brandId}`

      fetchTemplateByPosting(posting.jobId, posting.clientId, queryParameters).then(() => {
        if (posting && posting.jobId) {
          this.handleChatApplyForJobDescription(
            this.props.template.templatesList[0].id,
            posting.jobId,
            posting.clientId,
            this.getLocaleMap(locale).code,
            queryString.parse(analyticsParameters).utm_source || '',
            queryString.parse(analyticsParameters).utm_campaign || ''
          )
        }
      })
    }
    if (window.extraDataForHumanlyInCareerPage) {
      // This pause is to let <Helmet> finish rendering the scripts with the required functions.
      setTimeout(function () {
        if (window.addExtraDataForHumanlyInCareerPage) {
          window.addExtraDataForHumanlyInCareerPage(window.extraDataForHumanlyInCareerPage)
        }
      }, 100)
    }
  }

  getAnalyticsParameters(localeCode) {
    const {
      setAnalyticsParameters,
      analyticsParameters,
      location: { search },
    } = this.props

    const { utmSources } = this.state
    const isUtmSourceInQuery = 'utm_source' in parse(search)

    let newAnalyticsParameters = search

    try {
      const currentUri = window.location.href
      const windowOpener = window.opener ? window.opener.location.href : null
      let utmSourceReferrer = document.referrer ? document.referrer : windowOpener
      const shouldUseCustomUtmSource =
        (utmSourceReferrer === null || utmSourceReferrer === currentUri) && utmSources.size

      if (!isUtmSourceInQuery && (utmSourceReferrer || shouldUseCustomUtmSource)) {
        newAnalyticsParameters += `${search ? '&' : '?'}utm_source=${
          shouldUseCustomUtmSource
            ? utmSources.get(localeCode)
            : this.getUrlHostName(utmSourceReferrer)
        }`

        if (newAnalyticsParameters !== analyticsParameters) {
          setAnalyticsParameters(newAnalyticsParameters)
        }
      }
    } catch (e) {
      console.warn(e)
    }

    return newAnalyticsParameters
  }

  handleTrackView = async () => {
    const { params } = this.props.match
    if (
      'clientId' in params && // only append script if on correct page, since component used by multiple routes
      !document.getElementById('trackingView') // and only append new script if not already mounted
    ) {
      try {
        const { clientId, postingId } = params
        const trackingEventUrl = `${pixelTrackingURL}/v1/clients/${clientId}/events?reqId=${postingId}`
        const trackingResponse = await window.fetch(trackingEventUrl)
        const { view: viewEvents } = await trackingResponse.json()
        viewEvents.forEach(({ src, type }) => {
          let pixel
          if (type && type.includes('image')) {
            pixel = document.createElement('img')
            pixel.height = 1
            pixel.width = 1
            pixel.style.cssText = 'display: none;'
          } else {
            pixel = document.createElement('script')
            pixel.async = true
          }
          pixel.id = 'trackingView'
          pixel.src = src
          pixel.type = type
          type && type.includes('image')
            ? document.body.appendChild(pixel)
            : document.head.appendChild(pixel)
        })
      } catch (e) {
        console.warn(`There was a problem with the 'view' event tracking integration: ${e}.`)
      }
    }
  }

  handleChatApply = async (source, campaign) => {
    const {
      template: { templatesList },
    } = this.props
    const careerPageId = templatesList[0].id
    const clientId = templatesList[0].clientId
    const { locale } = this.state
    const filterLocale = LANGUAGE_MAP[locale].code
      ? LANGUAGE_MAP[locale].code
      : templatesList.filter((template) => template.defaultLocale === true)[0].locale

    let legacyIds = []
    const templateArr = templatesList.filter((template) => template.locale === filterLocale)
    if (templateArr && templateArr.length > 0 && templateArr[0].clients) {
      const currentTemplate = templateArr[0]
      legacyIds = currentTemplate.clients.map((legacyClientId) => {
        return legacyClientId.legacyClientId
      })
      if (legacyIds.length === 0) {
        legacyIds.push(clientId)
      }
    }

    try {
      const careerPageConfigUrl = `${chatApplyURL}/v1/clients/${clientId}/chatapply/public/${careerPageId}/${filterLocale}`
      const careerPageResponse = await window.fetch(careerPageConfigUrl)

      if (careerPageResponse.status === 200) {
        const data = await careerPageResponse.json()
        this.setState({
          careerPageConfig: {
            playbookId: data.playbookConfig.playbookId,
            playbookGreeting: data.greeting,
            playbookCustomerId: data.clientConfig.customerId,
            playbookLegacyIds: JSON.stringify(legacyIds),
            language: filterLocale,
            chatApplySource: source,
            chatApplyCampaign: campaign,
          },
        })
        const { careerPageConfig } = this.state
        if (window.reloadHumanlyJs && typeof window.reloadHumanlyJs === 'function') {
          window.reloadHumanlyJs(
            careerPageConfig.playbookCustomerId,
            careerPageConfig.playbookId,
            careerPageConfig.playbookGreeting,
            careerPageConfig.playbookLegacyIds,
            careerPageConfig.language,
            careerPageConfig.chatApplySource,
            careerPageConfig.chatApplyCampaign
          )
        }
      } else {
        this.setState({
          careerPageConfig: null,
        })
        if (window.removeHumanly && typeof window.removeHumanly === 'function') {
          window.removeHumanly()
        }
      }
    } catch (e) {
      console.warn(`There was a problem with the ChatApply service: ${e}.`)
    }
  }

  handleChatApplyForJobDescription = async (
    jobDescriptionPageId,
    jobPostingId,
    clientId,
    jobDescriptionLocale,
    utmSource,
    utmCampaign
  ) => {
    try {
      const jobDescriptionConfigUrl = `${chatApplyURL}/v1/clients/${clientId}/chatapply/public/${jobDescriptionPageId}/${jobDescriptionLocale}`
      if (
        window.removeHumanlyFromJobDescription &&
        typeof window.removeHumanlyFromJobDescription === 'function'
      ) {
        window.removeHumanlyFromJobDescription()
      }
      const chatApplyJobDescriptionResponse = await window.fetch(jobDescriptionConfigUrl)
      if (chatApplyJobDescriptionResponse.status === 200) {
        const data = await chatApplyJobDescriptionResponse.json()

        window.reloadHumanlyForJobDescriptionPage(
          jobPostingId,
          utmSource,
          data.greeting,
          data.playbookConfig.playbookId,
          data.clientConfig.customerId,
          jobDescriptionLocale,
          utmCampaign
        )
      }
    } catch (e) {
      console.warn(`There was a problem with the ChatApply Service: ${e}.`)
    }
  }

  getTemplateByLang = (lang) => {
    const { templatesList } = this.props.template
    if (templatesList === initialState.templatesList) {
      return initialState.templatesList[0]
    }

    const template = this.props.template.templatesList.find((template) => template.locale === lang)
    if (template) {
      return template
    } else {
      const defaultTemplate = this.props.template.templatesList.find(
        (template) => template.defaultLocale
      )
      // Updating template locale to be language from url rather than language from default template
      defaultTemplate.locale = lang
      return defaultTemplate
    }
  }

  getAsset = (assetPath, details = true) => {
    const { locale } = this.state
    const template = this.getTemplateByLang(this.getLocaleMap(locale).code)
    const { clientId } = template

    let assetId = ''
    const assetObjectPath = 'content.' + assetPath
    const asset = getFromObject(template, assetObjectPath, null)

    if (asset && asset.id) {
      return details ? asset.detail[asset.assetType] : asset
    } else {
      assetId = getFromObject(template, assetObjectPath + 'MaId', null)
    }

    const { assetsCollection, fetchAsset, reserveAssetKey } = this.props

    if (assetId && assetsCollection && assetsCollection[assetId]) {
      return assetsCollection[assetId]
    } else if (assetId) {
      reserveAssetKey(assetId)
      fetchAsset(clientId, assetId)
      return {}
    }
    return {}
  }

  getImageAssets = (obj, images = []) => {
    for (let x in obj) {
      if (obj[x].id && obj[x].assetType === 'image') {
        images.push(obj[x].detail[obj[x].assetType].fileId)
      } else if (typeof obj[x] === 'object') {
        this.getImageAssets(obj[x], images)
      }
    }
    return images
  }

  getFontFamilies = (obj, fontPath = '', fonts = []) => {
    for (let x in obj) {
      if (x === 'fontFamily') {
        fonts.push(fontPath + 'fontFamily')
      } else if (typeof obj[x] === 'object') {
        this.getFontFamilies(obj[x], fontPath + x + '.', fonts)
      }
    }
    return fonts
  }

  openLink(link) {
    window.open(link)
  }

  mobileMenu = () => {
    this.setState({
      mobileMenu: !this.state.mobileMenu,
    })
  }

  getRandomId = (array) => array[Math.floor(Math.random() * array.length)]

  getRandomImageId = () => {
    const { locale } = this.state
    const { content } = this.getTemplateByLang(this.getLocaleMap(locale).code)
    const allImageIds = [...new Set(this.getImageAssets(content))]

    return this.getRandomId(allImageIds)
  }

  getLogoAsset = () => {
    const { locale } = this.state
    const {
      content: { header, footer },
    } = this.getTemplateByLang(this.getLocaleMap(locale).code)

    let logoPath = null

    if (header.logo) {
      logoPath = 'header'
    } else if (footer.logo) {
      logoPath = 'footer'
    }

    return this.getAsset(`${logoPath}.logo`).fileId
  }

  changeLocale = (name, value) => {
    const template = this.getTemplateByLang(this.getLocaleMap(value).code)
    if (value !== LANGUAGE_CODE_TO_FLAG[template.locale]) return
    const { analyticsParameters } = this.props
    if (template && template.domain && !window.location.href.includes(template.domain)) {
      window.location.replace('//' + template.domain + analyticsParameters)
    }
    this.setState({ [name]: value }, () => {
      const { locale } = this.state
      const {
        template,
        fetchPosting,
        match: { params },
      } = this.props
      if (template.type === 'JobDescriptionPage') {
        fetchPosting(
          ElasticSearch.getPostingParameters(params.postingId, params.clientId),
          this.getLocaleMap(locale).elasticIndex
        )
      }
    })
  }

  handleLocaleChange = (event) => {
    const {
      match: {
        params: { locale },
      },
      location: { pathname, search },
    } = this.props
    const locationPath = this.removeTrailingSlash(pathname)
    const { name, value } = event.target
    this.changeLocale(name, value)
    this.mobileMenu()

    if (locationPath.indexOf(locale) === -1 && !isCustomDomain) {
      const path = `${locationPath}/${this.getLocaleMap(value).code}${search}`
      this.props.history.push(path)
    } else if (locationPath.indexOf(locale) === -1 && isCustomDomain) {
      const path = `/${this.getLocaleMap(value).code}${search}`
      this.props.history.push(path)
    } else {
      this.props.history.push(
        locationPath.replace('/' + locale, '/' + this.getLocaleMap(value).code) + search
      )
    }
  }

  removeTrailingSlash(str) {
    return str.replace(/\/+$/, '')
  }

  getLocaleMap(locale) {
    if (LANGUAGE_MAP[locale]) {
      return LANGUAGE_MAP[locale]
    } else {
      const template = this.props.template.templatesList.find((template) => template.defaultLocale)
      return template ? LANGUAGE_MAP[LANGUAGE_CODE_TO_FLAG[template.locale]] : LANGUAGE_MAP['US']
    }
  }

  render() {
    const { templatesList, loading } = this.props.template
    const { locale: stateLocale, jumpedToSection, careerPageConfig } = this.state

    const template = this.getTemplateByLang(this.getLocaleMap(stateLocale).code)
    const availableLocales = templatesList
      .filter((template) => template.published)
      .map((template) => template.locale)

    const {
      location: { pathname, search },
      snackbarsManager: { activeSnackbars },
      closeSnackbar,
      history,
      posting,
      template: { type },
      jobSearchResults,
      postingLoading,
      postingNotFound,
      match: { params },
    } = this.props

    if (postingNotFound) {
      return (
        <IntlProvider
          locale={this.getLocaleMap(stateLocale).file}
          messages={MESSAGES[this.getLocaleMap(stateLocale).file]}
        >
          <PostingNotFound clientId={params.clientId} />
        </IntlProvider>
      )
    } else if (!templatesList.length) {
      return (
        <IntlProvider
          locale={this.getLocaleMap(stateLocale).file}
          messages={MESSAGES[this.getLocaleMap(stateLocale).file]}
        >
          <NotFound />
        </IntlProvider>
      )
    }

    if (!posting.jobId) {
      let ATSPath = posting.url
      for (let langCode of ATSLangCodes) {
        ATSPath = ATSPath && ATSPath.replace(langCode, `l_${this.getLocaleMap(stateLocale).file}`)
      }

      return (
        <IntlProvider
          locale={this.getLocaleMap(stateLocale).file}
          messages={MESSAGES[this.getLocaleMap(stateLocale).file]}
        >
          <NotFound />
        </IntlProvider>
      )
    }
    const locale = LANGUAGE_CODE_TO_FLAG[template.locale]

    const {
      content,
      content: {
        header,
        customFormat: { css, js },
        sections,
        footer,
        footer: { links, socialLinks },
      },
    } = template

    if (type === 'RootPage') {
      return <RootPage />
    }

    const isCareerPage = type === 'CareerPage'

    const { analyticGoogleTrackingId } = template

    const availability =
      template.availability && template.availability.toLowerCase().includes('both')
        ? null
        : template.availability

    const { mobileMenu } = this.state

    if (loading || postingLoading) {
      return <Loading />
    }

    const previewModeEnabled = !!search && parse(search).preview

    if (
      !posting.url &&
      !isCareerPage &&
      !previewModeEnabled &&
      postingInitialState.posting !== posting
    ) {
      return (
        <IntlProvider
          locale={this.getLocaleMap(locale).file}
          messages={MESSAGES[this.getLocaleMap(locale).file]}
        >
          <NotFound />
        </IntlProvider>
      )
    }

    const sectionsFonts = [
      ...this.getFontFamilies(content),
      'header.font.fontFamily',
      'footer.font.fontFamily',
    ]

    const defaultTemplate = this.props.template.templatesList.find(
      (template) => template.defaultLocale
    )
    const flagLocale =
      locale || (defaultTemplate && LANGUAGE_CODE_TO_FLAG[defaultTemplate.locale]) || 'US'

    const pageTitle = posting.url
      ? `${posting.positionType} - ${posting.brand}`
      : header && header.title

    if ((jobSearchResults.hits || posting.url) && !jumpedToSection && !loading && template.id) {
      jumpToAnchor()
      this.setState({
        jumpedToSection: true,
      })
    }

    const clientName = posting.url
      ? posting.clientName
      : template.clients &&
        template.clients.find((client) => client.legacyClientId === template.clientId).clientName
    return (
      <IntlProvider
        locale={this.getLocaleMap(locale).file}
        messages={MESSAGES[this.getLocaleMap(locale).file]}
      >
        <div style={{ pointerEvents: previewModeEnabled ? 'none' : 'all' }}>
          {!!sectionsFonts.length && (
            <style>
              {sectionsFonts.map((font) => getFontFace(this.getAsset(font), template.clientId))}
            </style>
          )}
          <Helmet>
            <title>{pageTitle}</title>
            {!!posting.jobId && (
              <link
                rel="canonical"
                href={`${clientURL}/clients/${posting.clientId}/posting/${posting.jobId}`}
              />
            )}
            <link
              rel="icon"
              type="image/png"
              href={
                header &&
                header.favicon &&
                getAssetsFile(template.clientId, this.getAsset('header.favicon').fileId)
              }
              sizes="16x16"
            />
            <style>{css}</style>
            <script type="text/javascript">{js}</script>
            {analyticGoogleTrackingId && (
              <script
                async
                src={'https://www.googletagmanager.com/gtag/js?id=' + analyticGoogleTrackingId}
              />
            )}
            {analyticGoogleTrackingId && (
              <script>
                {`window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', '${analyticGoogleTrackingId}');
              `}
              </script>
            )}
            {availability && availability.toLowerCase().includes('internal') && (
              <meta name="robots" content="noindex" />
            )}
            {careerPageConfig && isCareerPage && (
              <script>
                {`function importHumanlyJs(playbookCustomerId, playbookId, greetingMessage, playbookLegacyIds, language, chatApplySource, chatApplyCampaign) {
                    !function(n,t,e,a,o){var r=n.humanly=n.humanly||[];["on"].forEach(function(t){r[t]=function(){var n=Array.prototype.slice.call(arguments);return n.unshift(t),r.push(n),r}}),r.load=function(n){r.push(["load",n]),(a=t.createElement(e)).async=1,a.src="https://static.humanly.io/lib/import.js",(o=t.getElementsByTagName(e)[0]).parentNode.insertBefore(a,o)}}(window,document,"script");
                    humanly.load(playbookCustomerId);
                    globalHumanlyCareerPageConfiguration = createHumanlyApiConfig(playbookCustomerId, playbookId, greetingMessage, playbookLegacyIds, language, chatApplySource, chatApplyCampaign);
                    humanly.on("ready", function(api) {
                      api.config(globalHumanlyCareerPageConfiguration);
                    });
                  } 
                  
                  function createHumanlyApiConfig(playbookCustomerId, playbookId, greetingMessage, playbookLegacyIds, language, chatApplySource, chatApplyCampaign) {    
                    globalHumanlyCareerPage = {
                      playbookCustomerId: playbookCustomerId,
                      playbook_id: parseInt(playbookId),
                      greeting: greetingMessage,
                      talentreef: {
                        client_ids: JSON.parse(playbookLegacyIds),
                        brand_names: (extraDataForCareerPage) ? extraDataForCareerPage.brands : [],
                        location_ids: (extraDataForCareerPage) ? extraDataForCareerPage.locations : [],
                        category_names:  (extraDataForCareerPage) ? extraDataForCareerPage.categories : [],
                        internal_or_external: (extraDataForCareerPage) ? extraDataForCareerPage.internalOrExternal : '',
                        language: language,
                        source: chatApplySource,
                        campaign: chatApplyCampaign
                      }
                    }
                    
                    return greetingMessage && greetingMessage !== 'null' ? {
                        playbook_id: globalHumanlyCareerPage.playbook_id,
                        greeting: globalHumanlyCareerPage.greeting,
                        talentreef: {
                          client_ids: globalHumanlyCareerPage.talentreef.client_ids,
                          brand_names: globalHumanlyCareerPage.talentreef.brand_names,
                          location_ids: globalHumanlyCareerPage.talentreef.location_ids,
                          category_names:  globalHumanlyCareerPage.talentreef.category_names,
                          internal_or_external: globalHumanlyCareerPage.talentreef.internal_or_external,
                          language: globalHumanlyCareerPage.talentreef.language,
                          source: globalHumanlyCareerPage.talentreef.source,
                          campaign: globalHumanlyCareerPage.talentreef.campaign
                        }
                      } : {
                        playbook_id: globalHumanlyCareerPage.playbook_id,
                        talentreef: {
                          client_ids: globalHumanlyCareerPage.talentreef.client_ids,
                          brand_names: globalHumanlyCareerPage.talentreef.brand_names,
                          location_ids: globalHumanlyCareerPage.talentreef.location_ids,
                          category_names:  globalHumanlyCareerPage.talentreef.category_names,
                          internal_or_external: globalHumanlyCareerPage.talentreef.internal_or_external,
                          language: globalHumanlyCareerPage.talentreef.language,
                          source: globalHumanlyCareerPage.talentreef.source,
                          campaign: globalHumanlyCareerPage.talentreef.campaign
                        }
                      }
                  }

                  function reloadHumanlyJs(playbookCustomerId, playbookId, greetingMessage, playbookLegacyIds, language, chatApplySource, chatApplyCampaign) {
                    globalHumanlyCareerPageConfiguration = createHumanlyApiConfig(playbookCustomerId, playbookId, greetingMessage, playbookLegacyIds, language, chatApplySource, chatApplyCampaign);
                    humanly.config(globalHumanlyCareerPageConfiguration);
                    if (humanlyContainer) {
                      humanlyContainer.style.display='';
                    } 
                  }

                  function removeHumanly() {
                    humanlyContainer  = document.getElementById("humanly-widget-container");
                    if (humanlyContainer) {
                      humanlyContainer.style.display='none';
                    }
                  }
                  
                  function addExtraDataForHumanlyInCareerPage(params) {
                    if (JSON.stringify(extraDataForCareerPage) !== JSON.stringify(params)) {
                      extraDataForCareerPage = params;
                      try {
                        importHumanlyJs(
                          "${careerPageConfig.playbookCustomerId}",
                          ${careerPageConfig.playbookId},
                          ${JSON.stringify(careerPageConfig.playbookGreeting)},
                          '${careerPageConfig.playbookLegacyIds}',
                          '${careerPageConfig.language}',
                          "${careerPageConfig.chatApplySource}",
                          "${careerPageConfig.chatApplyCampaign}");
                      } catch (e) {
                        
                      }
                    }
                  }
                  
                var globalHumanlyCareerPageConfiguration = globalHumanlyCareerPageConfiguration || null
                var extraDataForCareerPage = extraDataForCareerPage || null
                var globalHumanlyCareerPage = globalHumanlyCareerPage || null
                var humanlyContainer = humanlyContainer || null
                `}
              </script>
            )}
            {template && !isCareerPage && (
              <script>
                {`function importHumanlyForJobDescriptionPage(jobPostingId, utmSource, greetingMessage, playbookId, playbookCustomerId, jobDescriptionLocale, utmCampaign) {
                      !function(n,t,e,a,o){var r=n.humanly=n.humanly||[];["on"].forEach(function(t){r[t]=function(){var n=Array.prototype.slice.call(arguments);return n.unshift(t),r.push(n),r}}),r.load=function(n){r.push(["load",n]),(a=t.createElement(e)).async=1,a.src="https://static.humanly.io/lib/import.js",(o=t.getElementsByTagName(e)[0]).parentNode.insertBefore(a,o)}}(window,document,"script");
                      humanly.load(playbookCustomerId)
                      humanly.on("ready", function(api) {
                        api.config({
                        playbook_id: parseInt(playbookId), 
                        greeting: greetingMessage,
                        talentreef: {
                          posting_id: jobPostingId,
                          source: utmSource,
                          language: jobDescriptionLocale,
                          campaign: utmCampaign
                          }                      
                        })
                      })
                    }
                    
                    function removeHumanlyFromJobDescription() {
                      jobDescriptionHumanlyContainer = document.getElementById("humanly-widget-container")
                      if (jobDescriptionHumanlyContainer) {
                        jobDescriptionHumanlyContainer.style.display='none'
                      }
                    }
                    
                    function reloadHumanlyForJobDescriptionPage(jobPostingId, utmSource, greetingMessage, playbookId, playbookCustomerId, jobDescriptionLocale, utmCampaign) {
                      globalHumanlyJobDescriptionPage = {
                        playbook_id: parseInt(playbookId), 
                        greeting: greetingMessage,
                        talentreef: {
                          posting_id: jobPostingId,
                          source: utmSource,
                          language: jobDescriptionLocale,
                          campaign: utmCampaign
                          }    
                      }
                      if (typeof humanly !== 'undefined') {
                        humanly.config(globalHumanlyJobDescriptionPage)
                        if (jobDescriptionHumanlyContainer) {
                          jobDescriptionHumanlyContainer.style.display='';
                        }
                      } else {
                        importHumanlyForJobDescriptionPage(jobPostingId, utmSource, greetingMessage, playbookId, playbookCustomerId, jobDescriptionLocale, utmCampaign);
                      }
                    }
                    
                    var globalHumanlyJobDescriptionPage = globalHumanlyJobDescriptionPage || null
                    var jobDescriptionHumanlyContainer = jobDescriptionHumanlyContainer || null
                    `}
              </script>
            )}
          </Helmet>
          <Header
            mobileMenu={mobileMenu}
            hex={this.getAsset('header.backgroundColor').hex}
            id="header"
          >
            {header.logoMaId && (
              <Logo id="logo-wrapper">
                <img
                  id="logo-img"
                  className="logo"
                  src={getAssetsFile(template.clientId, this.getAsset('header.logo').fileId)}
                  alt={this.getAsset('header.logo').alternateText}
                />
              </Logo>
            )}
            <Menu
              id="menu"
              mobileMenu={mobileMenu}
              fontFamily={this.getAsset('header.font.fontFamily', false).name}
              size={header.font && header.font.fontSize}
              color={
                header.font &&
                header.font.fontColorMaId &&
                this.getAsset('header.font.fontColor').hex
              }
            >
              {sections &&
                sections.map((section, index) => {
                  return (
                    section.anchorTitle && (
                      <Link
                        id="menu-section-link"
                        onClick={this.mobileMenu}
                        key={section.id + index}
                        to={`${pathname}${search}#${encodeURIComponent(
                          section.title ? section.title.replace(/ /g, '-') : section.id
                        )}`}
                        scroll={(el) => {
                          window.scroll({
                            top: el.offsetTop - SECTION_OFFSET,
                            behavior: 'smooth',
                            block: 'start',
                          })
                        }}
                      >
                        {section.anchorTitle}
                      </Link>
                    )
                  )
                })}
              {header &&
                header.links &&
                header.links.map((link, index) => {
                  return (
                    <a
                      key={index}
                      href={link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="menu-additional-link"
                    >
                      {link.name}&nbsp;
                    </a>
                  )
                })}
              <MobileLocales>
                {availableLocales.length > 1 && (
                  <Select
                    name="locale"
                    disableUnderline={true}
                    id="locale"
                    value={flagLocale}
                    onChange={this.handleLocaleChange}
                    aria-label="Select Language"
                    renderValue={(value) => (
                      <FlexCenter>
                        <FlagIcon code={value} size={36} />{' '}
                        <span>{this.getLocaleMap(value).name}</span>
                      </FlexCenter>
                    )}
                  >
                    {availableLocales.map((locale) => {
                      const flagLocale = LANGUAGE_CODE_TO_FLAG[locale]
                      const { name } = this.getLocaleMap(flagLocale)
                      return (
                        <MenuItem key={flagLocale} value={flagLocale}>
                          <FlagIcon code={flagLocale} size={24} /> {name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                )}
              </MobileLocales>
            </Menu>
            <Locales>
              {availableLocales.length > 1 && (
                <Select
                  name="locale"
                  disableUnderline={true}
                  id="locale"
                  value={flagLocale}
                  onChange={this.handleLocaleChange}
                  aria-label="Select Language"
                  renderValue={(value) => <FlagIcon code={value} size={36} />}
                >
                  {availableLocales.map((locale) => {
                    const flagLocale = LANGUAGE_CODE_TO_FLAG[locale]
                    const { name } = this.getLocaleMap(flagLocale)
                    return (
                      <MenuItem key={flagLocale} value={flagLocale}>
                        <FlagIcon code={flagLocale} size={24} /> {name}
                      </MenuItem>
                    )
                  })}
                </Select>
              )}
            </Locales>
            <Hamburger
              id="hamburger-menu-button"
              mobileMenu={mobileMenu}
              onClick={this.mobileMenu}
              aria-label="Menu"
              color={
                header.font &&
                header.font.fontColorMaId &&
                this.getAsset('header.font.fontColor').hex
              }
            >
              <MenuIcon color="inherit" className="hamburger-menu-icon" />
            </Hamburger>
          </Header>
          <div style={{ marginTop: `${SECTION_OFFSET}px` }}>
            {content &&
              content.sections &&
              content.sections.map((section, index) => {
                return (
                  <ChooseSection
                    availability={availability}
                    key={index}
                    template={template}
                    sectionType={section.id}
                    styles={section}
                    parameters={search}
                    clientId={template.clientId}
                    clientName={clientName}
                    templateId={template.id}
                    history={history}
                    postingBrand={posting && posting.brand}
                    elasticLocale={this.getLocaleMap(locale).elasticIndex}
                    locale={this.getLocaleMap(locale).code}
                    fallbackShareImageId={
                      header.logoMaId || footer.logoMaId
                        ? getAssetsFile(template.clientId, this.getLogoAsset())
                        : getAssetsFile(template.clientId, this.getRandomImageId())
                    }
                    id={encodeURIComponent(
                      section.title ? section.title.replace(/ /g, '-') : section.id
                    )}
                  />
                )
              })}
          </div>
          <Footer hex={this.getAsset('footer.backgroundColor').hex} id="footer">
            <FooterLinks
              id="footer-links-wrapper"
              fontFamily={this.getAsset('footer.font.fontFamily', false).name}
              size={footer.font && footer.font.fontSize}
              color={
                footer.font &&
                footer.font.fontColorMaId &&
                this.getAsset('footer.font.fontColor').hex
              }
            >
              {links &&
                links.map((link, index) => {
                  return (
                    <a
                      key={index}
                      href={link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="footer-link"
                    >
                      {link.name}&nbsp;
                    </a>
                  )
                })}
            </FooterLinks>
            {footer.logoMaId && (
              <Logo id="footer-logo-wrapper">
                <img
                  id="footer-logo-img"
                  src={getAssetsFile(template.clientId, this.getAsset('footer.logo').fileId)}
                  alt={this.getAsset('footer.logo').alternateText}
                />
              </Logo>
            )}
            <div id="footer-social-links-wrapper">
              {socialLinks &&
                socialLinks.map((socialIcon) => {
                  return (
                    socialIcon && (
                      <a
                        key={socialIcon.type}
                        href={socialIcon.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="footer-social-link"
                      >
                        <SocialIcon
                          type={socialIcon.type.replace('+', 'plus')}
                          onClick={() => {}}
                        />
                      </a>
                    )
                  )
                })}
            </div>
          </Footer>
          <PrivacyPolicyContainer id="privacy-policy">
            Powered by Talentreef/Mitratech &nbsp;&nbsp;
            <a
              href={'https://www.talentreef.com/privacy-policy/'}
              rel="noopener noreferrer"
              target="_blank"
              aria-label="Privacy Policy"
            >
              Privacy Policy
            </a>
          </PrivacyPolicyContainer>
          <Snackbars id="snackbar" snackbarsList={activeSnackbars} closeSnackbar={closeSnackbar} />
        </div>
      </IntlProvider>
    )
  }
}

export const mapStateToProps = ({
  template,
  assetsCollection,
  snackbarsManager,
  jobPosting: { posting, postingLoading, postingNotFound },
  user: { analyticsParameters },
  jobSearchResults,
}) => ({
  template,
  assetsCollection: assetsCollection.assets,
  snackbarsManager,
  posting,
  analyticsParameters,
  jobSearchResults: jobSearchResults.jobSearchResults,
  postingLoading,
  postingNotFound,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchAsset,
      fetchTemplateByURL,
      fetchTemplateByClientId,
      setTemplateType,
      setAnalyticsParameters,
      fetchTemplateByPosting,
      fetchPosting,
      reserveAssetKey,
      setClientId,
      fetchJobDescriptionPage,
      closeSnackbar,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(App)
