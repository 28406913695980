export const queryParametersMapElastic = {
  contractType: 'contractType',
  postalCode: 'address.postalCode.raw',
  internalOrExternal: 'internalOrExternal',
  clientId: 'clientId.raw',
  locationNumber: 'location.number.raw',
  departmentName: 'department.name.raw',
  positionType: 'positionType.raw',
  city: 'address.city.raw',
  state: 'stateOrProvinceFull.raw',
  country: 'address.country',
  stateOrProvinceFull: 'stateOrProvinceFull.raw',
  stateOrProvince: 'address.stateOrProvince',
  category: 'category.raw',
  brand: 'brand.raw',
  clientName: 'clientName.raw',
  jobId: 'jobId.raw',
  socialRecruitingAttribute1: 'socialRecruitingAttribute1',
  brandId: 'brandId',
}
