import axios from 'axios'
import { CALLAPI } from '../middleware/callAPIMiddleware'
import { apiURLDomain } from '../../config/endpoints'

const FETCH_BRANDS = 'FETCH_BRANDS'
const FETCH_BRANDS_SUCCESS = 'FETCH_BRANDS_SUCCESS'
const FETCH_BRANDS_FAIL = 'FETCH_BRANDS_FAIL'
const RESET_BRANDS = 'RESET_BRANDS'

export const initialState = {
  brands: [],
  error: null,
  loading: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_BRANDS:
      return {
        ...initialState,
      }
    case FETCH_BRANDS:
      return {
        ...state,
        loading: true,
      }
    case FETCH_BRANDS_SUCCESS:
      return {
        ...state,
        brands: action.payload,
      }
    case FETCH_BRANDS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export const fetchBrands = (clientId) => ({
  type: CALLAPI,
  types: [FETCH_BRANDS, FETCH_BRANDS_SUCCESS, FETCH_BRANDS_FAIL],
  callAPI: () => axios.get(`${apiURLDomain}clients/${clientId}/recruit/public/brands`),
})
