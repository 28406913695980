import axios from 'axios'
import axiosRetry from 'axios-retry'
import { casLoginUrl, heimdallUrl, apiURL } from './endpoints'

axiosRetry(axios, { retries: 3 })

const axiosConfig = (store) => {
  store.subscribe(() => {
    const { routing } = store.getState()
    const pathname = routing.location.pathname

    // set the default base url for all axios requests
    axios.defaults.baseURL = `${apiURL}`

    //add 401 handler and redirect to CAS login
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          window.location.replace(`${casLoginUrl}${heimdallUrl}${pathname}`)
        }
        return Promise.reject(error)
      }
    )
  })
}

export default axiosConfig
