import { applyMiddleware, compose, createStore } from 'redux'
import { routerMiddleware } from 'react-router-redux'
import createHistory from 'history/createBrowserHistory'
import rootReducer from './reducers'
import thunkMiddleware from 'redux-thunk'
import callAPIMiddleware from './middleware/callAPIMiddleware'

export const history = createHistory()
// Create a history of your choosing (we're using a browser history in this case)

const enhancers = []
const initialState = {}
const middleware = [callAPIMiddleware, thunkMiddleware, routerMiddleware(history)]

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.devToolsExtension

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers)

const store = createStore(rootReducer, initialState, composedEnhancers)

export default store
