import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
// Import your reducers here
import user from './user'
import asset from './marketingAssets'
import assetsCollection from './marketingAssetsCollection'
import jobSearchResults from './jobSearch'
import jobPosting from './jobPosting'
import filters from './searchFielters'
import template from './template'
import locations from './locations'
import brands from './brands'
import snackbarsManager from './snackbarsManager'

export default combineReducers({
  // make sure to add imported reducer here
  asset,
  assetsCollection,
  jobSearchResults,
  jobPosting,
  filters,
  template,
  locations,
  brands,
  user,
  snackbarsManager,
  routing: routerReducer,
})
