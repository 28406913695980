import axios from 'axios'
import { CALLAPI } from '../middleware/callAPIMiddleware'
import { elasticSearchURL } from '../../config/endpoints'

const FETCH_JOBS = 'FETCH_JOBS'
const FETCH_JOBS_SUCCESS = 'FETCH_JOBS_SUCCESS'
const FETCH_JOBS_FAIL = 'FETCH_JOBS_FAIL'
const RESET_JOBS = 'RESET_JOBS'

const initialState = {
  jobSearchResults: {},
  error: null,
  loading: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_JOBS:
      return {
        ...initialState,
      }
    case FETCH_JOBS:
      return {
        ...state,
        loading: true,
      }
    case FETCH_JOBS_SUCCESS:
      return {
        ...state,
        jobSearchResults: action.payload.hits,
      }
    case FETCH_JOBS_FAIL:
      return {
        ...state,
        jobSearchResults: {},
        error: action.payload,
      }
    default:
      return state
  }
}

export function fetchJobs(elasticParameters, elasticIndex) {
  return {
    type: CALLAPI,
    types: [FETCH_JOBS, FETCH_JOBS_SUCCESS, FETCH_JOBS_FAIL],
    callAPI: () => axios.post(elasticSearchURL(elasticIndex), elasticParameters),
  }
}
