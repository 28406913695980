export const posting = {
  templatesList: [
    {
      type: 'Posting',
      locale: 'en',
      defaultLocale: true,
      published: true,
      content: {
        header: {},
        footer: {},
        customFormat: {
          css: '',
          js: '',
        },
        sections: [
          {
            id: 'position_information',
            title: 'Position Information',
            type: 'POSITION',
            anchorTitle: 'Position Information',
            position: {
              buttonShare: {
                backgroundColor: {
                  id: '2976fe64-4d93-461b-91b6-0df866d33e3c',
                  isActive: true,
                  assetType: 'color',
                  detail: {
                    color: {
                      hex: '#767676',
                    },
                  },
                },
              },
              buttonApply: {
                backgroundColor: {
                  id: '8f6d8196-37ea-4962-bff0-6bb8089d829a',
                  isActive: true,
                  assetType: 'color',
                  detail: {
                    color: {
                      hex: '#767676',
                    },
                  },
                },
              },
              additionalInfoFields: [
                'Company',
                'Position',
                'Status',
                'Shift',
                'Req #',
                'Date Posted',
                'Location',
                'Job Category',
              ],
            },
          },
        ],
      },
    },
  ],
  type: 'Posting',
  loading: false,
  error: null,
}
