const jumpToAnchor = () => {
  const SECTION_OFFSET = 113
  const {
    location: { hash },
  } = window
  if (hash !== '') {
    setTimeout(() => {
      const id = hash.replace('#', '')
      const element = document.getElementById(id)
      if (element) {
        window.scroll({
          top: element.offsetTop - SECTION_OFFSET,
          behavior: 'smooth',
          block: 'start',
        })
      }
    }, 1000)
  }
}

export { jumpToAnchor }
