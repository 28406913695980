import React, { Fragment } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import IntlTelInput from 'react-intl-tel-input'
import { Form, Field } from 'react-final-form'
import { parsePhoneNumber } from 'libphonenumber-js'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Helmet } from 'react-helmet'
import { EmailValidator } from 'commons-validator-js'
import { parse } from 'query-string'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'
import { states } from '../../static/enums'

import { Select } from 'tr-components/Select'
import { Colors } from 'tr-components/Colors'
import { RadioButton as StylessRadioButton } from 'tr-components/RadioButton'
import { Checkbox as StylessCheckbox } from 'tr-components/Checkbox'
import { ConfirmDialog as StylessConfirmDialog } from 'tr-components/ConfirmDialog'
import { NotificationDialog } from 'tr-components/NotificationDialog'
import { TextField as StylessTextField } from 'tr-components/TextField'

import { apiURLDomain } from '../../config/endpoints'

import { NAME_REGEXP } from '../../static/CONSTS'

const maxCharLength = 400

const ConfirmDialog = styled(StylessConfirmDialog)`
  && {
    z-index: 999
  }
  button:last-child {
    font-size: ${(p) => p.size}px;
    font-family: ${(p) => p.fontFamily}, sans-serif;
    ${(p) =>
      p.invalid
        ? `
      background-color: ${Colors.gray};
      pointer-events: none;
    `
        : `
    color: ${p.fontColor};
    background-color: ${p.color || Colors.lightGray};
    &:hover {
      filter: brightness(80%);
    }`}
  }
  button:first-child {
    color: ${Colors.darkGray}
    font-family: ${(p) => p.fontFamily}, sans-serif;
    font-size: ${(p) => p.size}px;
  }
  h2, svg, span, p {
    @media screen and (max-width: 500px) {
      font-size: .7rem;
    }
  }
`
const TextField = styled(StylessTextField)`
  input {
    ::placeholder {
      color: ${(p) => (p.error ? Colors.errorRed : Colors.lightGray)};
      opacity: 0.5;
    }
  }
`

const Button = styled.button`
  padding: 8px 14px;
  text-transform: uppercase;
  font-size: ${(p) => p.size}px;
  font-family: ${(p) => p.fontFamily}, sans-serif;
  display: inline-block;
  background: none;
  color: ${(p) => p.color || Colors.lightGray};
  border: none;
  cursor: pointer;
`
const RadioButton = styled(StylessRadioButton)`
  svg {
    color: ${(p) => p.color || Colors.lightGray};
  }
`
const Checkbox = styled(StylessCheckbox)`
  svg {
    color: ${(p) => p.color || Colors.lightGray};
  }
`

const IntlTelInputWrapper = styled.div`
  .intl-tel-input {
    width: 100%;
    height: 32px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    input {
      width: 100%;
      height: 32px;
      border: none;
      border-bottom: 2px solid ${Colors.gray};
      font-size: 16px;
      background-color: white;
      box-sizing: border-box;
      padding: 5px 10px;
    }
  }
`
const UpperCaseSemiHeader = styled.div`
  margin-top: 30px;
  text-transform: uppercase;
`
const InnerSection = styled.div`
  padding: 0 15px 0 50px;
`
const DescriptionWrapper = styled.div`
  height: ${(p) => (p.truncate ? '130px' : 'auto')};
  overflow: hidden;
  position: relative;
  transition: all 0.5s
    ${(p) =>
      p.truncate &&
      `&:after{
    content: "";
    box-shadow: inset 0 -56px 60px -13px rgba(255,255,255,.7);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 75px;
    overflow: hidden;
  }`};
`
const CharCounter = styled.div`
  color: ${Colors.darkBlue};
  height: 1.6rem;
  display: flex;
  justify-content: flex-end;
  height: 0;
`
const RadioButtonsContainer = styled.div`
  margin-top: 10px;
`
const Warning = styled.span`
  color: ${Colors.errorRed};
  align-self: center;
  font-size: 0.8em;
  width: 100%;
`
const Error = styled.div`
  color: ${Colors.red};
`

const InfoList = styled.ul`
  padding-left: 0;
  & > li {
    list-style-type: none;
  }
`

class ExtendedApplyModal extends React.Component {
  state = {
    truncate: true,
    informationDialog: false,
    submitError: null,
  }

  setPixelCode = (jobId) => {
    const body = document.getElementsByTagName('body')[0]
    const pixel1 = document.createElement('img')
    pixel1.src = `https://d.hodes.com/i?tv=pixel_tracker&aid=regisfranchise&p=web&e=se&se_ca=conversion&se_ac=Apply_Complete&se_pr=${this.props.clientId}&se_la=${jobId}`
    pixel1.style.cssText = 'display: none;'
    pixel1.style.width = '1px'
    pixel1.style.height = '1px'

    const pixel2 = document.createElement('img')
    pixel2.src = 'https://click.appcast.io/talentreef-9k/a17.png?ent=6&e=5559&t=1521757142536'
    pixel2.style.cssText = 'display: none;'
    pixel2.style.width = '1px'
    pixel2.style.height = '1px'

    const pixel3 = document.createElement('img')
    pixel3.src =
      '//pixel.mathtag.com/event/img?mt_id=1432480&mt_adid=207536&mt_exem=&mt_excl=&v1=&v2=&v3=&s1=&s2=&s3='
    pixel3.style.cssText = 'display: none;'
    pixel3.style.width = '1px'
    pixel3.style.height = '1px'

    window.fbq('track', 'SubmitApplication')
    body.appendChild(pixel1)
    body.appendChild(pixel2)
    body.appendChild(pixel3)
  }

  continueButton = (values) => {
    const {
      firstName,
      lastName,
      email,
      phone,
      addressLine1,
      addressLine2,
      state,
      city,
      zipCode,
      notifyBy,
      contactAgree,
    } = values
    const {
      analyticsParameters,
      templateId,
      clientId,
      locale,
      jobInfo: {
        internalOrExternal,
        address: { city: jobCity, stateOrProvince, country },
        category,
        brandId,
        jobId,
      },
    } = this.props

    if (contactAgree === 'No Thanks') notifyBy.length = 0

    const formData = {
      addressLine1,
      addressLine2,
      state,
      city,
      zipCode,
      jobLink: window.location.href.split('?')[0],
      subscription: {
        sourceType: 'JOB_PAGE_QUICK_APPLY_FORM',
        firstName,
        lastName,
        email: email.trim(),
        phone,
        addressLine1,
        addressLine2,
        city,
        state,
        zipCode,
        desiredBrandIds: [brandId],
        sendUpdatesViaEmail: notifyBy.includes('Email'),
        sendUpdatesViaText: notifyBy.includes('Text'),
        desiredJobCategories: [category],
        desiredLocations: [
          {
            country,
            city: jobCity,
            state: stateOrProvince,
          },
        ],
        locale,
      },
    }

    let data = new FormData()
    data.append('formData', JSON.stringify({ ...formData.subscription }))

    const internal = internalOrExternal ? `internal=${internalOrExternal !== 'externalOnly'}` : ''

    axios
      .post(
        `${apiURLDomain}clients/${clientId}/recruit/public/members${analyticsParameters}&originId=${templateId}&${internal}`,
        data
      )
      .then(
        (response) => {
          this.setPixelCode(jobId)
          axios.post(
            `v1/clients/${clientId}/jobs/${jobId}/quick-apply${analyticsParameters}&originId=${templateId}&${internal}`,
            {
              ...formData,
              subscription: {
                ...formData.subscription,
                memberId: response.data.memberId,
                talentCommunityId: response.data.talentCommunityId,
              },
            }
          )
          this.setState({
            informationDialog: true,
            submitError: null,
          })
        },
        (error) => {
          this.setState({
            informationDialog: true,
            submitError: error.response.data.validationFailures,
          })
        }
      )
  }

  truncateDescription = () => {
    this.setState({ truncate: !this.state.truncate })
  }

  validateForm = (values) => {
    const {
      firstName,
      lastName,
      email,
      contactAgree,
      notifyBy,
      phone = '',
      addressLine1,
      city,
      state,
      zipCode,
    } = values
    const { intl } = this.props
    const emailValidator = new EmailValidator()

    const errors = {}

    const nameErrorMassage = intl.formatMessage({ id: 'validation.notAllowedCharacters' })
    const contactAgreeApproved = contactAgree === 'Yes'

    if (!firstName) errors.firstName = intl.formatMessage({ id: 'validation.firstName' })
    if (!lastName) errors.lastName = intl.formatMessage({ id: 'validation.lastName' })
    if (!email) errors.email = intl.formatMessage({ id: 'validation.email' })
    if (!phone) errors.phone = intl.formatMessage({ id: 'validation.phone' })
    if (!addressLine1) errors.addressLine1 = intl.formatMessage({ id: 'validation.addressLine1' })
    if (!city) errors.city = intl.formatMessage({ id: 'validation.city' })
    if (!state) errors.state = intl.formatMessage({ id: 'validation.state' })
    if (!/^([a-zA-Z0-9 ]{1,10})$/.test(zipCode)) {
      errors.zipCode = intl.formatMessage({ id: 'validation.zipCode' })
    }
    if (!contactAgree || (contactAgreeApproved && !notifyBy.length))
      errors.contactAgree = intl.formatMessage({ id: 'validation.radioButton' })
    try {
      const phoneNumber = phone && parsePhoneNumber(phone)
      if (phoneNumber && !phoneNumber.isValid()) {
        errors.phone = intl.formatMessage({ id: 'validation.phone' })
      }
    } catch (error) {
      errors.phone = intl.formatMessage({ id: 'validation.phone' })
    }

    if (firstName && !NAME_REGEXP.test(firstName)) {
      errors.firstName = nameErrorMassage
    }
    if (lastName && !NAME_REGEXP.test(lastName)) {
      errors.lastName = nameErrorMassage
    }
    if (email && !emailValidator.isValid(email) && !errors.email) {
      errors.email = intl.formatMessage({ id: 'validation.email' })
    }

    return errors
  }

  handleCloseModal = (e) => {
    const { onCancel, analyticsParameters } = this.props
    const srcPage = parse(analyticsParameters)['utm_source']
    const isCareerPage =
      srcPage && srcPage.includes(window.location.host) && !srcPage.includes('posting')
    if (isCareerPage) {
      window.location.replace(srcPage)
    }
    e.preventDefault()
    onCancel()
  }

  render() {
    const { jobInfo, onCancel, color, size, fontFamily, fontColor, intl } = this.props
    const { truncate, informationDialog, submitError } = this.state

    const initialValues = { notifyBy: ['Text', 'Email'] }

    return (
      <Form
        validate={this.validateForm}
        onSubmit={this.continueButton}
        initialValues={initialValues}
        render={({ handleSubmit, values, invalid, errors, touched, form }) => {
          const {
            firstName,
            lastName,
            addressLine1,
            addressLine2,
            city,
            zipCode,
            email,
            contactAgree,
            notifyBy,
          } = values

          const contactAgreeApproved = contactAgree === 'Yes'

          return (
            <Fragment>
              <Helmet>
                <script>
                  {`!function(f,b,e,v,n,t,s)

                  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?

                    n.callMethod.apply(n,arguments):n.queue.push(arguments)};

                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';

                    n.queue=[];t=b.createElement(e);t.async=!0;

                    t.src=v;s=b.getElementsByTagName(e)[0];

                    s.parentNode.insertBefore(t,s)}(window,document,'script',

                  'https://connect.facebook.net/en_US/fbevents.js');

                  fbq('init', '513554462537583');

                  fbq('track', 'PageView');`}
                </script>
              </Helmet>

              <form onSubmit={handleSubmit} name="applyJob">
                {!informationDialog ? (
                  <ConfirmDialog
                    onCancel={onCancel}
                    open={this.props.open}
                    onAccept={handleSubmit}
                    acceptLabel={intl.formatMessage({ id: 'apply.continue' })}
                    cancelLabel={intl.formatMessage({ id: 'apply.cancel' })}
                    title={intl.formatMessage({ id: 'apply.step' })}
                    color={color}
                    size={size}
                    id="aplyModal"
                    fontFamily={fontFamily}
                    fontColor={fontColor}
                    invalid={invalid}
                  >
                    <p>
                      <FormattedMessage
                        id="apply.title"
                        defaultMessage="Please provide your basic contact information to start your application."
                      />
                    </p>
                    <div>
                      <UpperCaseSemiHeader>
                        {jobInfo && (
                          <span>
                            {jobInfo.positionType} - {jobInfo.address.street1},
                            {jobInfo.address.city},{jobInfo.address.country},
                            {jobInfo.address.stateOrProvince},{jobInfo.address.postalCode}
                          </span>
                        )}
                      </UpperCaseSemiHeader>
                      <DescriptionWrapper
                        truncate={jobInfo.description.length > maxCharLength && truncate}
                      >
                        <div dangerouslySetInnerHTML={{ __html: jobInfo.description }} />
                      </DescriptionWrapper>
                      {jobInfo.description.length > maxCharLength && (
                        <Button
                          onClick={this.truncateDescription}
                          color={color}
                          size={size}
                          fontFamily={fontFamily}
                        >
                          {truncate ? intl.formatMessage({ id: 'general.more' }) : 'LESS'}
                        </Button>
                      )}
                      <TextField
                        error={touched.firstName && errors && errors.firstName}
                        name="firstName"
                        placeholder={intl.formatMessage({ id: 'general.firstName' }) + ' *'}
                        inputProps={{
                          maxLength: '40',
                        }}
                        fullWidth
                      />
                      {firstName && <CharCounter>{firstName && firstName.length} / 40</CharCounter>}
                      <TextField
                        error={touched.lastName && errors && errors.lastName}
                        name="lastName"
                        placeholder={intl.formatMessage({ id: 'general.lastName' }) + ' *'}
                        type="text"
                        inputProps={{
                          maxLength: '40',
                        }}
                        fullWidth
                      />
                      {lastName && <CharCounter>{lastName && lastName.length} / 40</CharCounter>}
                      <TextField
                        error={touched.email && errors && errors.email}
                        name="email"
                        placeholder={intl.formatMessage({ id: 'general.email' }) + ' *'}
                        inputProps={{
                          maxLength: '254',
                        }}
                        fullWidth
                      />
                      {email && <CharCounter>{email && email.length} / 254</CharCounter>}
                      <Field
                        name="phone"
                        render={({ meta }) => (
                          <IntlTelInputWrapper>
                            <IntlTelInput
                              nationalMode={false}
                              defaultCountry="us"
                              preferredCountries={['us']}
                              format={true}
                              placeholder={intl.formatMessage({ id: 'general.phone' }) + ' *'}
                              onPhoneNumberChange={(status, value, countryData, phone) => {
                                form.change('phone', phone)
                              }}
                              css={['intl-tel-input', 'form-control']}
                            />
                            <Error>{meta.invalid && meta.dirty && <span>{meta.error}</span>}</Error>
                          </IntlTelInputWrapper>
                        )}
                      />
                      <TextField
                        error={touched.addressLine1 && errors && errors.addressLine1}
                        name="addressLine1"
                        placeholder={intl.formatMessage({ id: 'general.addressLine1' }) + ' *'}
                        type="text"
                        inputProps={{
                          maxLength: '50',
                        }}
                        fullWidth
                      />
                      {addressLine1 && (
                        <CharCounter>{addressLine1 && addressLine1.length} / 50</CharCounter>
                      )}
                      <TextField
                        error={touched.addressLine2 && errors && errors.addressLine2}
                        name="addressLine2"
                        placeholder={intl.formatMessage({ id: 'general.addressLine2' })}
                        type="text"
                        inputProps={{
                          maxLength: '50',
                        }}
                        fullWidth
                      />
                      {addressLine2 && (
                        <CharCounter>{addressLine2 && addressLine2.length} / 50</CharCounter>
                      )}
                      <TextField
                        error={touched.city && errors && errors.city}
                        name="city"
                        placeholder={intl.formatMessage({ id: 'general.city' }) + ' *'}
                        type="text"
                        inputProps={{
                          maxLength: '30',
                        }}
                        fullWidth
                      />
                      {city && <CharCounter>{city && city.length} / 30</CharCounter>}
                      <Grid container spacing={8}>
                        <Grid item xs={12} sm={6}>
                          <Select
                            fullWidth
                            name="state"
                            displayEmpty
                            inputProps={{
                              name: 'state',
                              id: 'state',
                            }}
                          >
                            <MenuItem value="">
                              <FormattedMessage
                                id="general.state"
                                defaultMessage="Select State *"
                              />
                            </MenuItem>
                            {states.map((state) => {
                              return (
                                <MenuItem key={state.value} value={state.value}>
                                  {state.display}
                                </MenuItem>
                              )
                            })}
                          </Select>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            error={touched.zipCode && errors && errors.zipCode}
                            name="zipCode"
                            placeholder={intl.formatMessage({ id: 'general.zipCode' }) + ' *'}
                            type="text"
                            inputProps={{
                              maxLength: '10',
                            }}
                            fullWidth
                          />
                          {zipCode && <CharCounter>{zipCode && zipCode.length} / 10</CharCounter>}
                        </Grid>
                      </Grid>
                      <div style={{ marginTop: '30px' }}>
                        <UpperCaseSemiHeader>
                          <FormattedMessage
                            id="talentCommunity.selectUpdates"
                            defaultMessage='PLEASE SELECT "YES" TO RECEIVE CAREER UPDATES FROM '
                          />
                          <span> {jobInfo.brand}</span>
                          {(!contactAgree || (contactAgreeApproved && !notifyBy.length)) && (
                            <Warning>*</Warning>
                          )}
                        </UpperCaseSemiHeader>
                        <RadioButtonsContainer>
                          <RadioButton
                            name="contactAgree"
                            label={intl.formatMessage({ id: 'general.yes' })}
                            color={color}
                            value="Yes"
                          />
                          {contactAgreeApproved && (
                            <InnerSection>
                              <span style={{ fontSize: '0.8em' }}>
                                <FormattedMessage
                                  id="talentCommunity.updatesQuestion"
                                  defaultMessage="Great! How would you like to receive these updates?"
                                />
                              </span>
                              <div style={{ display: 'flex' }}>
                                <Checkbox
                                  name="notifyBy"
                                  label={intl.formatMessage({ id: 'general.text' })}
                                  value="Text"
                                  color={color}
                                />
                                {notifyBy.includes('Text') && (
                                  <Warning>
                                    <FormattedMessage
                                      id="talentCommunity.warning"
                                      defaultMessage="Standard message & data rates may apply"
                                    />
                                  </Warning>
                                )}
                              </div>
                              <Checkbox
                                name="notifyBy"
                                label={intl.formatMessage({ id: 'general.email' })}
                                value="Email"
                                color={color}
                              />
                            </InnerSection>
                          )}
                          <div>
                            <RadioButton
                              name="contactAgree"
                              label={intl.formatMessage({ id: 'talentCommunity.no' })}
                              color={color}
                              value="No Thanks"
                            />
                          </div>
                        </RadioButtonsContainer>
                      </div>
                      <span style={{ fontSize: '10px' }}>
                        By clicking “Yes,” you agree and consent via your electronic signature to
                        receive calls at the number(s) you provided, which may deliver autodialed,
                        live and/or prerecorded messages and/or text messages by or on behalf of{' '}
                        {jobInfo.clientName}. You agree that calls and text messages may include,
                        but are not limited to, promotional, advertising, and/or marketing content
                        regarding {jobInfo.clientName}’s goods, services, and job openings. You
                        acknowledge that message and data rates may apply. Your consent is not a
                        condition of {jobInfo.clientName} providing goods or services to you or
                        considering your application.{' '}
                      </span>
                    </div>
                  </ConfirmDialog>
                ) : (
                  <NotificationDialog
                    onClose={this.handleCloseModal}
                    open={this.props.open}
                    buttonLabel={intl.formatMessage({ id: 'apply.complete' })}
                    title={
                      submitError
                        ? intl.formatMessage({ id: 'talentCommunity.oops' })
                        : intl.formatMessage({ id: 'talentCommunity.success' })
                    }
                  >
                    {!submitError ? (
                      <div>
                        <p>{intl.formatMessage({ id: 'apply.thankYouForApply' })}</p>
                        {jobInfo && (
                          <InfoList>
                            <li>
                              {intl.formatMessage({ id: 'positionInfo.company' })}:{' '}
                              {jobInfo.clientName}
                            </li>
                            <li>
                              {intl.formatMessage({ id: 'positionInfo.position' })}:{' '}
                              {jobInfo.positionType}
                            </li>
                            <li>
                              {intl.formatMessage({ id: 'positionInfo.location' })}:{' '}
                              {`${jobInfo.address.street1}, ${jobInfo.address.city}, ${jobInfo.address.country}, ${jobInfo.address.stateOrProvince}, ${jobInfo.address.postalCode}`}
                            </li>
                            <li>
                              {intl.formatMessage({ id: 'positionInfo.category' })}:{' '}
                              {jobInfo.category}
                            </li>
                          </InfoList>
                        )}
                        <p>{intl.formatMessage({ id: 'apply.lookingForward' })}</p>
                      </div>
                    ) : (
                      <p>{submitError.map((error) => error.fieldErrorMessage).join('\n')}</p>
                    )}
                  </NotificationDialog>
                )}
              </form>
            </Fragment>
          )
        }}
      />
    )
  }
}

export default injectIntl(ExtendedApplyModal)
