import 'core-js/es6/map'
import 'core-js/es6/set'
import 'core-js/es6/symbol'
import 'core-js/fn/array/find'
// import 'core-js/fn/array/filter'
import 'core-js/fn/array/from'
import 'core-js/fn/array/includes'
import 'core-js/fn/string/includes'
import smoothscroll from 'smoothscroll-polyfill'

smoothscroll.polyfill()

global.requestAnimationFrame = function (callback) {
  setTimeout(callback, 0)
}
