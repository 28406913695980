import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import getFromObject from 'lodash/get'
import sortBy from 'lodash/sortBy'
import { FormattedMessage } from 'react-intl'

import { bindActionCreators } from 'redux'
import { fetchAsset, reserveAssetKey } from '../../redux/reducers/marketingAssetsCollection'
import { fetchLocations } from '../../redux/reducers/locations'
import { fetchBrands, initialState as initialBrandsState } from '../../redux/reducers/brands'

import JoinTalentCommunityForm from './JoinTalentCommunityForm'
import { fetchJobFilters } from '../../redux/reducers/searchFielters'
import ElasticSearch from '../../services/elasticSearch'

const SectionTitle = styled.h1`
  font-family: ${(font) => font.fontFamily}, sans-serif;
  font-size: ${(font) => (font.size ? `${font.size}px` : 'inherit')};
  color: ${(font) => font.color};
  text-align: center;
`

const Article = styled.article`
  margin: 0 auto;
  padding: 0 20px;

  h1,
  h2 {
    text-transform: uppercase;
    text-align: center;
  }
  .center {
    text-align: center;
    padding: 10px 0;
  }
`

const FontStyled = styled.div`
  display: inline-block;
  font-family: ${(font) => font.fontFamily}, sans-serif;
  font-size: ${(font) => (font.size ? `${font.size}px` : 'inherit')};
  color: ${(font) => font.color};
`

const SectionBackground = styled.div`
  margin: 0 auto;
  width: 100%;
  padding: 20px 0;
  background: ${(props) => props.color || 'white'};
  text-align: center;
`

export class TalentCommunity extends React.Component {
  componentDidMount() {
    const {
      fetchLocations,
      fetchJobFilters,
      fetchBrands,
      template: {
        clientId,
        clients,
        brands: templateBrands,
        jobCategories,
        availability,
        locale,
        brandId,
        locationIds,
      },
      brands: clientBrands,
      brandsState,
      postingBrand,
      elasticLocale,
    } = this.props
    let brands =
      templateBrands && templateBrands.length
        ? templateBrands
        : clientBrands.map((brand) => brand.name)
    if (postingBrand) {
      brands = [postingBrand]
    }
    const clientIds = clients
      ? clients.map((client) => client.legacyClientId)
      : clientId && [clientId]
    if (templateBrands || brandsState !== initialBrandsState || postingBrand || brandId) {
      fetchJobFilters(
        ElasticSearch.getFilterParameters(
          clientIds,
          brands,
          locationIds,
          jobCategories,
          null,
          null,
          availability
        ),
        elasticLocale
      )
    }

    fetchLocations(clientIds, locale)
    if (brandsState === initialBrandsState) {
      fetchBrands(clientId)
    }
  }
  getAsset = (assetPath, details = true) => {
    const { assetsCollection, fetchAsset, clientId, reserveAssetKey, styles } = this.props

    let assetId = ''
    const asset = getFromObject(styles, assetPath, null)
    if (asset && asset.id) {
      return details ? asset.detail[asset.assetType] : asset
    } else {
      assetId = getFromObject(styles, assetPath + 'MaId', null)
    }

    if (assetId && assetsCollection && assetsCollection[assetId]) {
      return assetsCollection[assetId]
    } else if (assetId) {
      reserveAssetKey(assetId)
      fetchAsset(clientId, assetId)
      return {}
    }
    return {}
  }

  render() {
    const {
      analyticsParameters,
      id,
      filters: { jobFilters },
      templateId,
      template: { availability },
      template,
      templateType,
      postingBrand,
      clientId,
      clientName,
      locations,
      brands,
      locale,
      styles: {
        title,
        fontTitle,
        joinTalentCommunity: { buttonJoin },
      },
    } = this.props

    let locationsList = []
    const selectedBrands = template.brands || postingBrand || [template.brandId] || []
    const sortedBrands = selectedBrands.length
      ? brands &&
        brands.filter((brand) => selectedBrands.includes(brand.name)).map((brand) => brand.name)
      : brands && sortBy(brands, 'name').map((brand) => brand.name)

    for (let brand in locations.countries) {
      locationsList = [...locationsList, ...locations.countries[brand]]
    }

    const titleFontFamily = this.getAsset('fontTitle.fontFamily', false).name

    return (
      <SectionBackground id={id} color={this.getAsset('backgroundColor').hex}>
        <header id="talent-community-header">
          <SectionTitle
            id="talent-community-title"
            fontFamily={titleFontFamily}
            size={fontTitle && fontTitle.fontSize}
            color={this.getAsset('fontTitle.fontColor').hex}
          >
            {title}
          </SectionTitle>
          <div>
            <FontStyled
              id="talent-community-subtitle"
              fontFamily={titleFontFamily}
              size={fontTitle && fontTitle.fontSize}
              color={this.getAsset('fontTitle.fontColor').hex}
            >
              <FormattedMessage
                id="talentCommunity.title"
                defaultMessage="Sign up to receive job updates"
              />
            </FontStyled>
          </div>
        </header>

        <Article>
          <section id="talent-community-form-section">
            <div className="center">
              <JoinTalentCommunityForm
                id="talent-community-form-wrapper"
                analyticsParameters={analyticsParameters}
                locale={locale}
                save={this.saveForm}
                templateType={templateType}
                button={buttonJoin || {}}
                categories={jobFilters.categories}
                getAsset={this.getAsset}
                locations={locationsList}
                brands={sortedBrands}
                allBrands={brands}
                templateId={templateId}
                availability={availability}
                clientId={clientId}
                clientName={clientName}
                color={this.getAsset('fontTitle.fontColor').hex}
                titleFontFamily={titleFontFamily}
              />
            </div>
          </section>
        </Article>
      </SectionBackground>
    )
  }
}

const mapStateToProps = ({ assetsCollection, filters, locations, brands, template, user }) => ({
  assetsCollection: assetsCollection.assets,
  filters,
  locations: locations,
  brands: brands.brands,
  brandsState: brands,
  templateType: template.type,
  analyticsParameters: user.analyticsParameters,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchAsset,
      fetchLocations,
      fetchBrands,
      reserveAssetKey,
      fetchJobFilters,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(TalentCommunity)
