import React from 'react'
import PropTypes from 'prop-types'

import SearchResults from './SearchResults'
import Media from './Media'
import JobCategories from './JobCategories'
import TalentCommunity from './TalentCommunity'
import PositionInformation from './PositionInformation'

const ChooseSection = ({ sectionType, ...rest }) => {
  switch (sectionType) {
    case 'career_search':
      return <SearchResults {...rest} />
    case 'media':
      return <Media {...rest} />
    case 'job_categories':
      return <JobCategories {...rest} />
    case 'talent':
      return <TalentCommunity {...rest} />
    case 'position_information':
      return <PositionInformation {...rest} />
    default:
      return null
  }
}

ChooseSection.propTypes = {
  sectionType: PropTypes.string.isRequired,
}

export default ChooseSection
