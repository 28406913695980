import React, { Fragment } from 'react'
import { Route, Switch } from 'react-router-dom'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { theme } from 'tr-components/MuiTheme'
import { App, NotFound, UnsubscribeEmail, UnsubscribeSms } from '../components'

// eslint-disable-next-line react/display-name
const withTheme = (Component) => (props) =>
  (
    <MuiThemeProvider theme={theme}>
      <Component {...props} />
    </MuiThemeProvider>
  )

const baseUrl = '/'

const Routes = ({ childProps }) => (
  <Route
    render={() => (
      <Fragment>
        <Switch>
          <Route component={App} exact path={baseUrl} props={childProps} />
          <Route component={App} exact path={'/clients/:clientId'} props={childProps} />
          <Route
            component={UnsubscribeEmail}
            exact
            path={'/unsubscribe/email'}
            props={childProps}
          />
          <Route component={UnsubscribeSms} exact path={'/unsubscribe/sms'} props={childProps} />
          <Route component={App} exact path={'/:templateURL'} props={childProps} />
          <Route component={App} exact path={'/:templateURL/:locale'} props={childProps} />
          <Route
            component={App}
            exact
            path={'/clients/:clientId/posting/:postingId'}
            props={childProps}
          />
          <Route
            component={App}
            exact
            path={'/clients/:clientId/posting/:postingId/:locale'}
            props={childProps}
          />
          <Route
            component={App}
            exact
            path={'/job-description-page/:jobDescriptionId'}
            props={childProps}
          />
          <Route
            component={App}
            exact
            path={'/job-description-page/:jobDescriptionId/:locale'}
            props={childProps}
          />
          {/* Catch all unmatched routes */}
          <Route component={NotFound} />
        </Switch>
      </Fragment>
    )}
  />
)

export default withTheme(Routes)
