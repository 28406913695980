import React from 'react'
import { Container } from 'tr-components/Container'
import { MessageCard } from 'tr-components/MessageCard'
import { injectIntl } from 'react-intl'
import ReactHtmlParser from 'react-html-parser'

export class NotFound extends React.Component {
  render() {
    const { intl, clientId } = this.props

    return (
      <Container>
        <MessageCard
          title={intl.formatMessage({ id: 'posting.not.found.title' })}
          message={ReactHtmlParser(
            intl.formatMessage({ id: 'posting.not.found.message' }, { clientId })
          )}
        />
      </Container>
    )
  }
}

export default injectIntl(NotFound)
