import React, { Fragment } from 'react'
import { injectIntl } from 'react-intl'
import styled from 'styled-components'
import axios from 'axios'
import { parse } from 'query-string'
import { Helmet } from 'react-helmet'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Email from '@material-ui/icons/EmailSharp'
import { apiURLDomain } from '../config/endpoints'

const Container = styled(Grid)`
  height: 100vh;
`

const Title = styled.h4`
  text-align: center;
`

export class UnsubscribeEmail extends React.Component {
  state = {
    unsubscibeSuccess: false,
    unsubscibeFail: false,
  }

  unsubscribe = () => {
    const { clientId, subscriptionId, signature } = parse(this.props.location.search)

    axios
      .delete(
        `${apiURLDomain}clients/${clientId}/recruit/public/members/${subscriptionId}/preferences/email?signature=${signature}`
      )
      .then(() => {
        this.setState({ unsubscibeSuccess: true })
      })
      .catch(() => {
        this.setState({ unsubscibeFail: true })
      })
  }

  render() {
    const { email, companyName } = parse(this.props.location.search)

    return (
      <Fragment>
        <Helmet>
          <title>Unsubscribe</title>
        </Helmet>
        <Container container direction="column" justify="center" alignItems="center">
          <Grid item>
            <Email style={{ fontSize: 80 }} color="primary" />
          </Grid>
          {!(this.state.unsubscibeSuccess || this.state.unsubscibeFail) && (
            <Fragment>
              <Grid item>
                <Title>
                  If you wish to no longer receive notifications to <u>{email}</u> click the button
                  below.
                </Title>
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" onClick={this.unsubscribe}>
                  Unsubscribe
                </Button>
              </Grid>
            </Fragment>
          )}
          {this.state.unsubscibeSuccess && (
            <Grid item>
              <Title>You have been unsubscribed from the Talent Community for {companyName}</Title>
            </Grid>
          )}
          {this.state.unsubscibeFail && (
            <Grid item>
              <Title>Something went wrong. Please try again later</Title>
            </Grid>
          )}
        </Container>
      </Fragment>
    )
  }
}

export default injectIntl(UnsubscribeEmail)
