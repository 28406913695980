import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'

import SnackbarContentWrapper from './SnackbarContentWrapper'

const snackbarPosition = {
  position: 'fixed',
  bottom: '0',
  left: '50%',
}

const Snackbars = ({ snackbarsList, closeSnackbar }) =>
  !!snackbarsList.length &&
  snackbarsList.map((snackbar, index) => (
    <Snackbar style={snackbarPosition} key={index} open={index >= 0}>
      <SnackbarContentWrapper
        message={snackbar.message}
        onClose={closeSnackbar}
        variant={snackbar.type}
      />
    </Snackbar>
  ))

export default Snackbars
