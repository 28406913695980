import axios from 'axios/index'
import { CALLAPI } from '../middleware/callAPIMiddleware'
import { posting as postingDefaultTemplate } from '../../config/defaultTemplates'

const FETCH_TEMPLATE = 'template/FETCH_FORMS'
const FETCH_TEMPLATE_SUCCESS = 'template/FETCH_TEMPLATE_SUCCESS'
const FETCH_TEMPLATE_FAIL = 'template/FETCH_TEMPLATE_FAIL'
const FETCH_POSTING_TEMPLATE_FAIL = 'template/FETCH_POSTING_TEMPLATE_FAIL'

const RESET_STATE = 'template/RESET_STATE'
const SET_TEMPLATE_TYPE = 'template/SET_TEMPLATE_TYPE'

export const initialState = {
  templatesList: [
    {
      type: 'CareerPage',
      locale: 'en',
      content: {
        header: {},
        sections: [],
        customFormat: {},
        footer: {
          links: [],
          social_links: [],
        },
      },
    },
  ],
  type: 'CareerPage',
  loading: false,
  error: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TEMPLATE:
      return {
        ...state,
        loading: true,
      }
    case FETCH_TEMPLATE_SUCCESS:
      return {
        ...state,
        templatesList: action.payload,
        loading: false,
      }
    case FETCH_TEMPLATE_FAIL:
      return {
        ...state,
        loading: false,
        type: 'RootPage',
        error: action.payload,
      }
    case FETCH_POSTING_TEMPLATE_FAIL:
      return {
        ...postingDefaultTemplate,
        loading: false,
        error: action.payload,
      }
    case SET_TEMPLATE_TYPE:
      return {
        ...state,
        type: action.payload,
      }
    case RESET_STATE:
      return {
        ...initialState,
      }
    default:
      return state
  }
}

export const fetchTemplateByURL = (templateURL) => ({
  type: CALLAPI,
  types: [FETCH_TEMPLATE, FETCH_TEMPLATE_SUCCESS, FETCH_TEMPLATE_FAIL],
  callAPI: () => axios.get(`/careerPages/alias/${templateURL}`),
})

export const fetchTemplateByClientId = (clientId) => ({
  type: CALLAPI,
  types: [FETCH_TEMPLATE, FETCH_TEMPLATE_SUCCESS, FETCH_TEMPLATE_FAIL],
  callAPI: () => axios.get(`/clients/${clientId}/careerPages/`),
})

export const fetchTemplateByPosting = (jobId, clientId, queryParameters) => ({
  type: CALLAPI,
  types: [FETCH_TEMPLATE, FETCH_TEMPLATE_SUCCESS, FETCH_POSTING_TEMPLATE_FAIL],
  callAPI: () => axios.get(`/v1/clients/${clientId}/posting/${jobId + queryParameters}`),
})

export const fetchJobDescriptionPage = (templateURL) => ({
  type: CALLAPI,
  types: [FETCH_TEMPLATE, FETCH_TEMPLATE_SUCCESS, FETCH_TEMPLATE_FAIL],
  callAPI: () => axios.get(`/jobDescriptionPages/${templateURL}`),
})

export const setTemplateType = (templateType) => ({
  type: SET_TEMPLATE_TYPE,
  payload: templateType,
})

export const resetState = () => ({
  type: RESET_STATE,
})
