import { queryParametersMapElastic } from '../static/elasticMap'

const noneFilterParameters = ['preview']
const availabilityFilterParameters = ['internalOnly', 'internalWithExpiration', 'externalOnly']

const multiMatchTemplate = (searchWord, type, additionalParams) => ({
  multi_match: {
    query: searchWord,
    fields: [
      'positionType^3',
      'socialRecruitingAttribute1^3',
      'address.city',
      'address.street1',
      'stateOrProvinceFull',
      'brand',
      'clientName',
    ],
    type: type,
    ...additionalParams,
  },
})

export default class ElasticSearch {
  static isNotAnExclusiveInternalFilter(item) {
    return (
      !item.terms['internalOrExternal'] &&
      !item.terms['location.id'] &&
      !item.terms['brand.raw'] &&
      !item.terms['clientId.raw']
    )
  }

  static isFiltered(
    filters,
    keywordSearch,
    currentInternalFilterAmounts,
    currentExternalFilterAmounts
  ) {
    const possibleInternalAndExternalFilterKeys = filters
      .filter(this.isNotAnExclusiveInternalFilter)
      .map((filter) => Object.keys(filter.terms)[0])

    const internalFilterAmount = (filter) => Object.values(filter)[0]
    const internalFilterThreshold = (filter) =>
      currentInternalFilterAmounts[Object.keys(filter)[0]] ? 1 : 0

    const isNotAnInternalFilter = (item) => {
      const isAnExternalFilterDespiteBeenAnInternalOne = () => {
        const realInternalAndExternalFilterKeys = possibleInternalAndExternalFilterKeys
          .map((filterKey) => ({
            [filterKey]: filters
              .map((filter) => (filter.terms[filterKey] ? 1 : 0))
              .reduce((prev, curr) => prev + curr, 0),
          }))
          .filter((filter) => internalFilterAmount(filter) > internalFilterThreshold(filter))
          .reduce((prev, curr) => ({ ...prev, ...curr }), {})

        return Object.keys(realInternalAndExternalFilterKeys).length !== 0
      }

      const isAnExclusiveExternalFilter = (item) =>
        currentExternalFilterAmounts[Object.keys(item.terms)[0]]
          ? currentExternalFilterAmounts[Object.keys(item.terms)[0]] !== 0
          : false

      return (
        (this.isNotAnExclusiveInternalFilter(item) &&
          isAnExternalFilterDespiteBeenAnInternalOne()) ||
        isAnExclusiveExternalFilter(item)
      )
    }
    const hasExternalFilters = () => filters.filter(isNotAnInternalFilter).length > 0
    const hasKeywordSearch = () => keywordSearch.must && keywordSearch.must.length > 0

    return hasExternalFilters() || hasKeywordSearch()
  }

  static setOrderField(
    elasticRequestData,
    orderField,
    order,
    filters,
    keywordSearch,
    currentInternalFilterAmounts,
    currentExternalFilterAmounts
  ) {
    if (orderField) {
      return {
        ...elasticRequestData,
        sort: [{ [orderField]: { order: order } }],
      }
    }

    if (
      this.isFiltered(
        filters,
        keywordSearch,
        currentInternalFilterAmounts,
        currentExternalFilterAmounts
      )
    ) {
      return {
        ...elasticRequestData,
        sort: [{ 'address.postalCode.raw': { order: 'asc' } }],
      }
    }

    return {
      ...elasticRequestData,
      sort: [{ 'positionType.raw': { order: 'asc' } }],
    }
  }

  static getSearchParameters(
    page,
    size,
    clientIds,
    brands,
    locationIds = [],
    availability,
    searchWord = null,
    categories = null,
    state = null,
    city = null,
    orderField = null,
    order = 'asc',
    queryFilters = {},
    internalCategoryFilters = [],
    externalCategoryFilters = []
  ) {
    const keywordSearch = searchWord
      ? {
          must: [
            {
              bool: {
                should: [
                  multiMatchTemplate(searchWord, 'best_fields', {
                    analyzer: 'search_analyzer',
                    boost: 2,
                  }),
                  multiMatchTemplate(searchWord, 'phrase_prefix', { boost: 2 }),
                  multiMatchTemplate(searchWord, 'phrase_prefix', {
                    analyzer: 'standard',
                    boost: 2,
                  }),

                  {
                    term: {
                      'address.postalCode.raw': searchWord,
                    },
                  },
                  ...searchWord.split(' ').map((word) => multiMatchTemplate(word.trim(), 'phrase')),
                  {
                    term: {
                      'jobId.raw': searchWord,
                    },
                  },
                ],
                minimum_should_match: 1,
              },
            },
          ],
        }
      : {}

    const clientIdFilter = clientIds ? { terms: { 'clientId.raw': clientIds } } : null
    const brandsFilter = brands.length ? { terms: { 'brand.raw': brands } } : null
    const locationsFilter = locationIds.length ? { terms: { 'location.id': locationIds } } : null
    const stateOrProvinceFilter =
      state && state.length ? { terms: { 'stateOrProvinceFull.raw': state } } : null
    const cityFilter = city && city.length ? { terms: { 'address.city.raw': city } } : null

    const categoriesFilter =
      categories && categories.length ? this.getCategoriesFilterParams(categories) : null
    const availabilityFilter = availability ? this.getAvailibilityFilterParams(availability) : null

    const filters = [
      clientIdFilter,
      brandsFilter,
      locationsFilter,
      categoriesFilter,
      stateOrProvinceFilter,
      availabilityFilter,
      cityFilter,
    ].filter((filter) => filter !== null)

    for (let filter in queryFilters) {
      if (
        filter &&
        queryFilters[filter] &&
        queryFilters[filter].length &&
        !noneFilterParameters.includes(filter) &&
        queryParametersMapElastic[filter]
      ) {
        filters.push({
          terms: {
            [queryParametersMapElastic[filter]]: Array.isArray(queryFilters[filter])
              ? queryFilters[filter]
              : [queryFilters[filter]],
          },
        })
      }
    }

    let elasticRequestData = {
      from: page,
      size: size,
      _source: [
        'positionType',
        'category',
        'socialRecruitingAttribute1',
        'description',
        'address',
        'jobId',
        'clientId',
        'clientName',
        'brandId',
        'brand',
        'clientName',
        'location',
        'internalOrExternal',
        'url',
      ],
      query: {
        bool: {
          ...keywordSearch,
          filter: filters,
        },
      },
    }

    const currentInternalFilterAmounts = {
      'category.raw': internalCategoryFilters.length,
    }

    const currentExternalFilterAmounts = {
      'category.raw': externalCategoryFilters.length,
    }

    elasticRequestData = this.setOrderField(
      elasticRequestData,
      orderField,
      order,
      filters,
      keywordSearch,
      currentInternalFilterAmounts,
      currentExternalFilterAmounts
    )

    return elasticRequestData
  }

  static getPostingParameters(postingId, clientId) {
    return {
      query: {
        bool: {
          filter: [{ term: { jobId: postingId } }, { term: { 'clientId.raw': clientId } }],
        },
      },
    }
  }

  static getFilterParameters(
    clientIds,
    brands = [],
    locationIds = [],
    categories = [],
    state = null,
    city = null,
    availability
  ) {
    const clientIdFilter = clientIds ? { terms: { 'clientId.raw': clientIds } } : null
    const brandsFilter = brands.length ? { terms: { 'brand.raw': brands } } : null
    const locationsFilter = locationIds.length ? { terms: { 'location.id': locationIds } } : null
    const stateOrProvinceFilter =
      state && state.length ? { terms: { 'stateOrProvinceFull.raw': state } } : null
    const cityFilter = city && city.length ? { terms: { 'address.city.raw': city } } : null

    const categoriesFilter = categories.length ? this.getCategoriesFilterParams(categories) : null
    const availabilityFilter =
      availability && availability !== 'BOTH'
        ? this.getAvailibilityFilterParams(availability)
        : null

    const filters = [
      clientIdFilter,
      brandsFilter,
      locationsFilter,
      categoriesFilter,
      stateOrProvinceFilter,
      availabilityFilter,
      cityFilter,
    ].filter((filter) => filter !== null)

    return {
      size: 0,
      query: {
        bool: {
          filter: filters,
        },
      },
      aggs: {
        categories: {
          terms: { field: 'category.raw', size: 100000 },
        },
        state: {
          terms: { field: 'stateOrProvinceFull.raw', size: 100000 },
        },
        city: {
          terms: { field: 'address.city.raw', size: 100000 },
        },
      },
    }
  }

  static getCategoriesFilterParams(categories) {
    const targetKey = Array.isArray(categories) ? 'terms' : 'term'
    return { [targetKey]: { 'category.raw': categories } }
  }

  static getAvailibilityFilterParams(availability) {
    const mappedAvailabilityFilterParameters = {
      internal: availabilityFilterParameters.slice(0, 2),
      external: availabilityFilterParameters.slice(-1),
    }
    const filterParametersArray = mappedAvailabilityFilterParameters[availability.toLowerCase()]
    const availabilityFiltersTermsArray =
      filterParametersArray &&
      filterParametersArray.map((key) => ({
        internalOrExternal: key,
      }))

    return { terms: { internalOrExternal: availabilityFiltersTermsArray } }
  }

  static parseQuery(queryString) {
    let query = {}
    const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&')
    for (let i = 0; i < pairs.length; i++) {
      var pair = pairs[i].split('=')
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '')
    }
    return query
  }
}
