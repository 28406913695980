const CLOSE_SNACKBAR = 'snackbar/CLOSE_SNACKBAR'
const OPEN_SNACKBAR = 'snackbar/OPEN_SNACKBAR'

export const ACTION_TYPES = {
  OPEN_SNACKBAR,
  CLOSE_SNACKBAR,
}

export const initialState = {
  activeSnackbars: [],
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CLOSE_SNACKBAR: {
      const snackbars = state.activeSnackbars.slice()
      snackbars.splice(payload.snackbarIndex, 1)

      return {
        ...state,
        activeSnackbars: snackbars,
      }
    }
    case OPEN_SNACKBAR: {
      const activeSnackbars = state.activeSnackbars.slice()
      activeSnackbars.push(payload.snackbar)

      return {
        ...state,
        activeSnackbars,
      }
    }
    default: {
      return state
    }
  }
}

export const openSnackbar = (snackbar = { message: 'Default Snackbar', type: 'success' }) => ({
  type: OPEN_SNACKBAR,
  payload: { snackbar },
})

export const closeSnackbar = (snackbarIndex) => ({
  type: CLOSE_SNACKBAR,
  payload: { snackbarIndex },
})

export const handleSnackbar = (snackbar, snackbarIndex) => (dispatch, getState) => {
  const {
    snackbarsManager: { activeSnackbars },
  } = getState()

  if (!activeSnackbars.length) {
    dispatch(openSnackbar(snackbar))
    setTimeout(() => dispatch(closeSnackbar(snackbarIndex)), snackbar.timeout || 2000)
  }
}
