import React, { Fragment } from 'react'
import { injectIntl } from 'react-intl'
import styled from 'styled-components'
import axios from 'axios'
import { Helmet } from 'react-helmet'
import { parse } from 'query-string'
import Grid from '@material-ui/core/Grid'
import Sms from '@material-ui/icons/SmsSharp'

import { apiURLDomain } from '../config/endpoints'

const Container = styled(Grid)`
  height: 100vh;
`

const Title = styled.h4`
  text-align: center;
`

export class UnsubscribeSms extends React.Component {
  state = {
    unsubscibeInProgress: false,
    unsubscibeSuccess: false,
    unsubscibeFail: false,
  }

  componentDidMount() {
    const { clientId, subscriptionId, signature } = parse(this.props.location.search)

    axios
      .delete(
        `${apiURLDomain}clients/${clientId}/recruit/public/members/${subscriptionId}/preferences/sms?signature=${signature}`
      )
      .then(() => {
        this.setState({ unsubscibeSuccess: true })
      })
      .catch(() => {
        this.setState({ unsubscibeFail: true })
      })
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Unsubscribe</title>
        </Helmet>
        <Container container direction="column" justify="center" alignItems="center">
          <Grid item>
            <Sms style={{ fontSize: 80 }} color="primary" />
          </Grid>
          {this.state.unsubscibeSuccess && (
            <Grid item>
              <Title>You have been unsubscribed from receiving SMS/Text communications</Title>
            </Grid>
          )}
          {this.state.unsubscibeFail && (
            <Grid item>
              <Title>Something went wrong. Please try again later</Title>
            </Grid>
          )}
        </Container>
      </Fragment>
    )
  }
}

export default injectIntl(UnsubscribeSms)
