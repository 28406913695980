import React from 'react'
import { Container } from 'tr-components/Container'
import { MessageCard } from 'tr-components/MessageCard'
import { injectIntl } from 'react-intl'

export class NotFound extends React.Component {
  render() {
    const { intl } = this.props
    return (
      <Container>
        <MessageCard
          title={intl.formatMessage({ id: '404.title' })}
          message={intl.formatMessage({ id: '404.message' })}
        />
      </Container>
    )
  }
}

export default injectIntl(NotFound)
