export const states = [
  { value: 'AL', display: 'AL - Alabama' },
  { value: 'AK', display: 'AK - Alaska' },
  { value: 'AZ', display: 'AZ - Arizona' },
  { value: 'AR', display: 'AR - Arkansas' },
  { value: 'CA', display: 'CA - California' },
  { value: 'CO', display: 'CO - Colorado' },
  { value: 'CT', display: 'CT - Connecticut' },
  { value: 'DE', display: 'DE - Delaware' },
  { value: 'DC', display: 'DC - District of Columbia' },
  { value: 'FL', display: 'FL - Florida' },
  { value: 'GA', display: 'GA - Georgia' },
  { value: 'HI', display: 'HI - Hawaii' },
  { value: 'ID', display: 'ID - Idaho' },
  { value: 'IL', display: 'IL - Illinois' },
  { value: 'IN', display: 'IN - Indiana' },
  { value: 'IA', display: 'IA - Iowa' },
  { value: 'KS', display: 'KS - Kansas' },
  { value: 'KY', display: 'KY - Kentucky' },
  { value: 'LA', display: 'LA - Louisiana' },
  { value: 'ME', display: 'ME - Maine' },
  { value: 'MD', display: 'MD - Maryland' },
  { value: 'MA', display: 'MA - Massachusetts' },
  { value: 'MI', display: 'MI - Michigan' },
  { value: 'MN', display: 'MN - Minnesota' },
  { value: 'MS', display: 'MS - Mississippi' },
  { value: 'MO', display: 'MO - Missouri' },
  { value: 'MT', display: 'MT - Montana' },
  { value: 'NE', display: 'NE - Nebraska' },
  { value: 'NV', display: 'NV - Nevada' },
  { value: 'NH', display: 'NH - New Hampshire' },
  { value: 'NJ', display: 'NJ - New Jersey' },
  { value: 'NM', display: 'NM - New Mexico' },
  { value: 'NY', display: 'NY - New York' },
  { value: 'NC', display: 'NC - North Carolina' },
  { value: 'ND', display: 'ND - North Dakota' },
  { value: 'OH', display: 'OH - Ohio' },
  { value: 'OK', display: 'OK - Oklahoma' },
  { value: 'OR', display: 'OR - Oregon' },
  { value: 'PA', display: 'PA - Pennsylvania' },
  { value: 'RI', display: 'RI - Rhode Island' },
  { value: 'SC', display: 'SC - South Carolina' },
  { value: 'SD', display: 'SD - South Dakota' },
  { value: 'TN', display: 'TN - Tennessee' },
  { value: 'TX', display: 'TX - Texas' },
  { value: 'UT', display: 'UT - Utah' },
  { value: 'VT', display: 'VT - Vermont' },
  { value: 'VA', display: 'VA - Virginia' },
  { value: 'WA', display: 'WA - Washington' },
  { value: 'WV', display: 'WV - West Virginia' },
  { value: 'WI', display: 'WI - Wisconsin' },
  { value: 'WY', display: 'WY - Wyoming' },
  { value: 'PR', display: 'PR - Puerto Rico' },
  { value: 'GU', display: 'GU - Guam' },
  { value: 'MP', display: 'MP - Northern Marianas Islands' },
  { value: 'VI', display: 'VI - Virgin Islands' },
  { value: 'NA', display: 'NA - Other - non U.S.' },
]

export const provinces = [
  { value: 'AB', display: 'AB - Alberta' },
  { value: 'BC', display: 'BC - British Columbia' },
  { value: 'MB', display: 'MB - Manitoba' },
  { value: 'NB', display: 'NB - New Brunswick' },
  { value: 'NL', display: 'NL - Newfoundland and Labrador' },
  { value: 'NT', display: 'NT - Northwest Territories' },
  { value: 'NS', display: 'NS - Nova Scotia' },
  { value: 'NU', display: 'NU - Nunavut' },
  { value: 'ON', display: 'ON - Ontario' },
  { value: 'PE', display: 'PE - Prince Edward Island' },
  { value: 'QC', display: 'QC - Quebec' },
  { value: 'SK', display: 'SK - Saskatchewan' },
  { value: 'YT', display: 'YT - Yukon' },
]
