import React from 'react'

import axios from 'axios'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { isAndroid, isIOS, isMobileOnly } from 'react-device-detect'
import SocialIcon from '../SocialIcon'
import { FACEBOOK_APP_ID } from '../../config/api'
import { originUrl } from '../../config/endpoints'

const ShareButtons = ({
  clientName,
  clientId,
  jobId,
  locale,
  positionType,
  templateId,
  templateType,
  positionLocation,
}) => {
  const getApplyUrl = (utm_source, utm_medium) => {
    const { search } = window.location

    return `${originUrl}/clients/${clientId}/posting/${jobId}/${locale}${
      search || '?'
    }&utm_source=${utm_source}&utm_medium=${utm_medium}`
  }

  const registerShare = (shareType) => () => {
    axios.post(`/jobs/${jobId}/register-share`, {
      pageId: templateId,
      pageType: templateType,
      sharedVia: shareType,
    })
  }

  const shareViaSocialMedia = (type) => () => {
    const { search } = window.location
    const url = encodeURIComponent(
      `${originUrl}/clients/${clientId}/posting/${jobId}/${locale}${
        search || '?'
      }&utm_source=${type}&utm_medium=social`
    )
    const sharingUrls = {
      facebook: `https://www.facebook.com/dialog/share?app_id=${FACEBOOK_APP_ID}&display=popup&href=${url}`,
      twitter: `https://twitter.com/intent/tweet?text=${positionType} in ${clientName}, ${positionLocation}.&url=${url}`,
      linkedin: `https://www.linkedin.com/shareArticle?mini=true&url=${url}`,
    }
    const sharingUrl = `${sharingUrls[type]}` || '#'
    registerShare(type)()
    window.open(sharingUrl, '_blank')
  }

  const sharingUrl = {
    email: getApplyUrl('email_share', 'email'),
    sms: getApplyUrl('sms_share', 'sms'),
  }

  const shareViaEmailOrSms = (type) => () => {
    registerShare(type)()
    const sharingPrefix =
      type === 'email'
        ? `mailto:?subject=${encodeURIComponent(
            positionType.replace('&', '&')
          )} at ${encodeURIComponent(clientName)}&`
        : `sms:${(isAndroid && '?') || (isIOS && '&')}`
    window.open(
      sharingPrefix +
        'body=' +
        encodeURIComponent(
          `Hi! I thought you might be interested in this job opportunity as a ${
            isAndroid ? positionType.replace('&', '%26') : positionType
          } at ${clientName} at ${positionLocation} ${sharingUrl[type]}`
        )
    )
  }

  return (
    <div>
      <SocialIcon type="facebook" onClick={shareViaSocialMedia('facebook')} />
      <SocialIcon type="twitter" onClick={shareViaSocialMedia('twitter')} />
      <SocialIcon type="linkedin" onClick={shareViaSocialMedia('linkedin')} />
      <span onClick={shareViaEmailOrSms('email')}>
        <SocialIcon type="email" />
      </span>
      {isMobileOnly && (
        <span onClick={shareViaEmailOrSms('sms')}>
          <SocialIcon type="sms" />
        </span>
      )}
    </div>
  )
}

ShareButtons.propTypes = {
  clientName: PropTypes.string.isRequired,
  clientId: PropTypes.number.isRequired,
  jobId: PropTypes.number.isRequired,
  locale: PropTypes.string.isRequired,
  positionType: PropTypes.string.isRequired,
  templateId: PropTypes.string.isRequired,
  templateType: PropTypes.string.isRequired,
}

export default injectIntl(ShareButtons)
