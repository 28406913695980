import axios from 'axios'
import { CALLAPI } from '../middleware/callAPIMiddleware'

const FETCH_LOCATIONS = 'FETCH_LOCATIONS'
const FETCH_LOCATIONS_SUCCESS = 'FETCH_LOCATIONS_SUCCESS'
const FETCH_LOCATIONS_FAIL = 'FETCH_LOCATIONS_FAIL'
const RESET_LOCATIONS = 'RESET_LOCATIONS'

const initialState = {
  countries: {},
  error: null,
  loading: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_LOCATIONS:
      return {
        ...initialState,
      }
    case FETCH_LOCATIONS:
      return {
        ...state,
        loading: true,
      }
    case FETCH_LOCATIONS_SUCCESS: {
      const response = action.payload
      let countries = {}
      for (let country of Object.keys(response)) {
        for (let brand of Object.keys(response[country])) {
          if (Object.keys(countries).includes(brand)) {
            countries[brand] = [...countries[brand], ...response[country][brand]]
          } else {
            countries[brand] = [...response[country][brand]]
          }
        }
      }
      return {
        ...state,
        countries,
      }
    }
    case FETCH_LOCATIONS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export function fetchLocations(clientIds, locale) {
  return {
    type: CALLAPI,
    types: [FETCH_LOCATIONS, FETCH_LOCATIONS_SUCCESS, FETCH_LOCATIONS_FAIL],
    callAPI: () => axios.post(`/v1/clients/dictionary/countries/view/${locale}`, { clientIds }),
  }
}
