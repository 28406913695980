import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Pagination } from 'tr-components/Pagination'
import { Colors } from 'tr-components/Colors'
import sortBy from 'lodash/sortBy'
import getFromObject from 'lodash/get'
import { parse, stringify } from 'query-string'

import { withStyles } from '@material-ui/core/styles'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import ArrowDropUp from '@material-ui/icons/ArrowDropUp'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Popper from '@material-ui/core/Popper'
import Fade from '@material-ui/core/Fade'
import Paper from '@material-ui/core/Paper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

import { bindActionCreators } from 'redux'
import { fetchAsset, reserveAssetKey } from '../../redux/reducers/marketingAssetsCollection'
import { fetchJobs } from '../../redux/reducers/jobSearch'
import { fetchJobFilters, fetchCitiesFilter } from '../../redux/reducers/searchFielters'
import { fetchBrands, initialState as initialBrandsState } from '../../redux/reducers/brands'

import ElasticSearch from '../../services/elasticSearch'
import ShareButtons from './ShareButtons'

import {
  Table,
  TableRow as StylessTableRow,
  TableHead as StylessTableHead,
  TableCell as StylessTableCell,
  TableBody as StylessTableBody,
  HeaderRow as StylessHeaderRow,
} from 'tr-components/Table'

const SectionTitle = styled.h1`
  font-family: ${(font) => font.fontFamily}, sans-serif;
  font-size: ${(font) => (font.size ? `${font.size}px` : 'inherit')};
  color: ${(font) => font.color};
  text-align: center;
`
const HeaderRow = styled(StylessHeaderRow)`
  padding: 0;
  border: none;
  @media screen and (max-width: 780px) {
    justify-content: space-between;
  }
  && .tcell {
    padding: 0;
  }
`
const TableHead = styled(StylessTableHead)`
  @media screen and (max-width: 980px) {
    .second-cell {
      display: none;
    }
  }
  @media screen and (max-width: 780px) {
    .no-mobile {
      display: none;
    }
    && .mobile {
      width: 50%;
      padding: 0;
      > div {
        @media screen and (max-width: 500px) {
          font-size: .7rem;
      }
    }
  }
`
const TableBody = styled(StylessTableBody)`
  @media screen and (max-width: 980px) {
    .second-cell {
      display: none;
    }
  }
  @media screen and (max-width: 780px) {
    .no-mobile {
      display: none;
    }
    .mobile {
      width: 50%;
    }
  }
`
const TableRow = styled(StylessTableRow)`
  padding: 0;
  background: none;
  border: none;
  margin: 10px 0;

  @media screen and (max-width: 780px) {
    justify-content: space-between;
    border-bottom: 1px solid ${(p) => p.color};
  }
  > div {
    padding: 0;
    white-space: normal;
    display: block;
  }
`

const Location = styled.div`
  display: none;
  @media screen and (max-width: 780px) {
    display: block;
    font-style: italic;
    font-size: 0.9em;
    opacity: 0.8;
    line-height: 110%;
  }
`

const TableCell = styled(StylessTableCell)`
  ${(p) => {
    const isCustomAttribute = p.id === 'customAttribute'
    return {
      'white-space': isCustomAttribute && 'initial',
      'line-height': isCustomAttribute && 'initial',
    }
  }};
`

const AddressTableHead = styled.span`
  @media screen and (min-width: 780px) {
    display: none;
  }
`

const ButtonsCell = styled.div`
  display: flex;
  padding: 0;
  justify-content: flex-end;
  @media screen and (max-width: 380px) {
    flex-direction: column;
    padding-left: 50px;
    margin-bottom: 10px;
  }
`

const Input = styled.input`
  border-radius: 4px;
  background-color: white;
  width: 100%;
  height: 34px;
  line-height: 34px;
  border: 0;
  font-size: 16px;
  margin-bottom: 10px;
  padding: 0 10px;
`

const PaginationWrapper = styled.div`
  @media screen and (max-width: 780px) {
    .rows-per-page-wrap {
      display: none;
    }
  }
  > div > div {
    ${(p) => p.color && `${`color: ${p.color}`}`};
  }
  button.rpp-selected,
  button.page-button-selected {
    ${(p) => p.color && `${`background: ${p.color}`}`};
    color: white;
  }
  button.page-button-first,
  button.page-button-previous,
  button.page-button-next,
  button.page-button-last,
  button.pagination-rows-per-page-button,
  button.page-button-number {
    ${(p) => p.color && `${`color: ${p.color}`}`};
    &.rpp-selected,
    &.page-button-selected {
      color: white;
    }
  }
`

const FilterSection = styled.div`
  display: flex;
  > div {
    width: 100%;
    &:first-child {
      margin-left: 0;
    }
  }
  div[aria-haspopup='true']:focus {
    background: transparent;
  }
  @media screen and (max-width: 680px) {
    display: block;
  }
  @media screen and (min-width: 768px) {
    > div {
      margin-left: 20px;
    }
  }
`

const Article = styled.article`
  max-width: 980px;
  margin: 0 auto;
  padding: 0 20px;

  h2 {
    text-transform: uppercase;
    text-align: center;
  }

  .center {
    text-align: center;
    padding: 10px 0;
    margin-bottom: 8px;
  }
`

const SearchButton = styled.button`
  margin: 20px auto;
  padding 14px;
  text-transform: uppercase;
  font-size: 18px;
  border-radius: 3px;
  background-color: ${(props) => props.color || Colors.lightGray};
  display: block;
  color: white;
  border: none;
  font-weight: bold;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
  }
`
const ButtonStyle = `
  padding: 5px 15px;
  align-items: center;
  display: flex;
  text-transform: uppercase;
  font-size: 14px;
  border-radius: 3px;
  && {
    color: white;
  }
  border: none;
  font-weight: bold;
  cursor: pointer;
  height: 100%;
  width: 100%;
  @media screen and (max-width: 500px) {
    padding: 0 8px;
    div {
      font-size: 12px;
    }
  }
`
const Button = styled.button`
  ${ButtonStyle};
  background: ${(props) => props.color || Colors.lightGray};
  line-height: 32px;
`
const LinkButton = styled.a`
  ${ButtonStyle};
  background: ${(props) => props.color || Colors.lightGray};
  margin-right: 10px;
  @media screen and (max-width: 380px) {
    margin: 5px 0;
  }
`
const TextLink = styled.a`
  && {
    color: inherit;
  }
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`
const FontStyled = styled.div`
  display: inline-block;
  font-family: ${(font) => font.fontFamily}, sans-serif;
  font-size: ${(font) => (font.size ? `${font.size}px` : 'inherit')};
  color: ${(font) => font.color};
`

const OrderIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > svg:nth-child(even) {
    margin-top: -10px;
  }
  & .arrow-icon {
    height: 15px;
  }
`
const CenteredText = styled.div`
  text-align: center;
`

const SelectOptionText = styled.div`
  display: inline-flex;
  justify-content: space-between;
  width: 100% .icon {
    color: green;
  }
`

const SharingPopper = styled(Popper)`
  &:after {
    content: '';
    position: absolute;
    top: -10px;
    transform: translateX(-50%);
    left: 50%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #fff transparent;
  }
`

const SharingContainer = styled(Paper)`
  padding: 5px 10px;
`

const SharingTitle = styled.div`
  margin-bottom: 5px;
`

const Error = styled.span`
  color: #e18e8f;
  position: absolute;
  top: -28px;
  left: 0;
`

const OrderIcon = ({ arrow }) => {
  return (
    <OrderIconWrapper>
      {(arrow || arrow === null) && <ArrowDropUp className="arrow-icon" />}
      {(!arrow || arrow === null) && <ArrowDropDown className="arrow-icon" />}
    </OrderIconWrapper>
  )
}

const styles = () => ({
  formControl: {
    minWidth: '100%',
  },
  select: {
    width: '100%',
    borderRadius: '4px',
    backgroundColor: 'white',
    fontStyle: 'normal',
    marginBottom: '10px',
    border: '1px solid #cfcfcf',
    padding: '0 10px',
  },
  textInput: {
    border: '1px solid #cfcfcf',
    borderRadius: '4px',
    outline: 'none',
  },
})

const SectionBackground = styled.div`
  width: 100%;
  padding: 20px 0;
  background: ${(props) => props.color || 'white'};
`

const VerticalCenter = styled.div`
  display: flex;
  align-items: center;
`
const LastHeaderCell = styled(VerticalCenter)`
  justify-content: flex-end;
`

class SearchResults extends React.Component {
  state = {
    amount: 10,
    state: [],
    category: [],
    city: [],
    keywordsFilter: '',
    page: 0,
    orderTitle: null,
    order: null,
    currentJob: null,
    anchorEl: null,
    isSharingPopperOpened: false,
    stateOpen: false,
    categoryOpen: false,
    cityOpen: false,
  }

  componentDidMount() {
    const {
      fetchBrands,
      brandsState,
      template: { clientId },
    } = this.props
    this.fetchJobsAndFilters()
    if (brandsState === initialBrandsState) {
      fetchBrands(clientId)
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.elasticLocale !== this.props.elasticLocale ||
      prevProps.brands !== this.props.brands
    ) {
      this.fetchJobsAndFilters()
    }
  }

  fetchJobsAndFilters = () => {
    const {
      template: { clientId, brands: templateBrands, jobCategories, clients, locationIds },
      fetchJobs,
      fetchJobFilters,
      fetchCitiesFilter,
      availability,
      parameters,
      brands: clientBrands,
      brandsState,
      elasticLocale,
    } = this.props
    const { amount, page } = this.state
    let queryParams = parse(parameters)
    const { state, city, category } = queryParams
    if (state) {
      queryParams.state = Array.isArray(state) ? state : [state]
    } else {
      queryParams.state = []
    }
    if (city) {
      queryParams.city = Array.isArray(city) ? city : [city]
    } else {
      queryParams.city = []
    }
    if (category) {
      queryParams.category = Array.isArray(category) ? category : [category]
    } else {
      queryParams.category = []
    }

    this.setState({ ...queryParams })
    const keywords = queryParams['keywordsFilter']
    if (keywords) {
      delete queryParams['keywordsFilter']
    }
    const clientIds = clients
      ? clients.map((client) => client.legacyClientId)
      : clientId && [clientId]
    const brands = templateBrands.length ? templateBrands : clientBrands.map((brand) => brand.name)
    if (clientIds && (templateBrands.length || brandsState !== initialBrandsState)) {
      fetchJobs(
        ElasticSearch.getSearchParameters(
          page,
          amount,
          clientIds,
          brands,
          locationIds,
          availability,
          keywords,
          jobCategories,
          [],
          [],
          null,
          'asc',
          queryParams,
          jobCategories,
          category
        ),
        elasticLocale
      )
      fetchJobFilters(
        ElasticSearch.getFilterParameters(
          clientIds,
          brands,
          locationIds,
          jobCategories,
          [],
          [],
          availability
        ),
        elasticLocale
      ).then(() => {
        const extraDataForHumanlyInCareerPage = {
          brands: brands,
          locations: locationIds,
          categories: jobCategories,
          internalOrExternal:
            availability && availability !== 'BOTH'
              ? ElasticSearch.getAvailibilityFilterParams(
                  availability
                ).terms.internalOrExternal.map((item) => item.internalOrExternal)
              : null,
        }
        if (
          window.addExtraDataForHumanlyInCareerPage &&
          typeof window.addExtraDataForHumanlyInCareerPage === 'function'
        ) {
          window.addExtraDataForHumanlyInCareerPage(extraDataForHumanlyInCareerPage)
        } else {
          window.extraDataForHumanlyInCareerPage = extraDataForHumanlyInCareerPage
        }
      })
      fetchCitiesFilter(
        ElasticSearch.getFilterParameters(
          clientIds,
          brands,
          locationIds,
          jobCategories,
          queryParams.state,
          [],
          availability
        ),
        elasticLocale
      )
    }
  }

  getAsset = (assetPath, details = true) => {
    const { assetsCollection, fetchAsset, clientId, reserveAssetKey, styles } = this.props

    let assetId = ''
    const asset = getFromObject(styles, assetPath, null)
    if (asset && asset.id) {
      return details ? asset.detail[asset.assetType] : asset
    } else {
      assetId = getFromObject(styles, assetPath + 'MaId', null)
    }

    if (assetId && assetsCollection && assetsCollection[assetId]) {
      return assetsCollection[assetId]
    } else if (assetId) {
      reserveAssetKey(assetId)
      fetchAsset(clientId, assetId)
      return {}
    }
    return {}
  }

  searchJobs = () => {
    const { city, state, category, keywordsFilter } = this.state
    const {
      push,
      location: { search },
    } = this.props.history
    push({
      search: stringify({
        ...parse(search),
        keywordsFilter: keywordsFilter,
        city: city,
        state: state,
        category: category,
      }),
    })
    this.setState({
      page: 0,
      orderTitle: null,
    })
    this.resetFilters(this.state.amount)
  }

  handleSelectChange = (event) => {
    const {
      template: { clientId, clients, brands: templateBrands, categories, locationIds },
      fetchCitiesFilter,
      elasticLocale,
      brands: clientBrands,
    } = this.props

    const clientIds = clients
      ? clients.map((client) => client.legacyClientId)
      : clientId && [clientId]
    const { name, value } = event.target
    const brands = templateBrands.length ? templateBrands : clientBrands.map((brand) => brand.name)
    if (name === 'state') {
      this.setState({ city: [] }, () => {
        const { state } = this.state
        fetchCitiesFilter(
          ElasticSearch.getFilterParameters(clientIds, brands, locationIds, categories, state),
          elasticLocale
        )
      })
    }

    this.handleSelectClose(name)()

    this.setState(
      {
        [name]: value,
      },
      () => {
        if (name !== 'keywordsFilter') this.searchJobs()
      }
    )
  }

  handleAmountChange = (rowsPerPage) => {
    const { order, orderTitle } = this.state
    this.setState({
      amount: rowsPerPage,
      page: 0,
    })

    this.resetFilters(rowsPerPage, 0, orderTitle, order)
  }

  handleChangePage = (page) => {
    const { amount, order, orderTitle } = this.state
    this.setState({ page: page - 1 })

    this.resetFilters(amount, (page - 1) * amount, orderTitle, order)
  }

  handleOrderChange = (title) => () => {
    const { amount, order } = this.state
    this.setState(
      {
        page: 0,
        order: !order,
        orderTitle: title,
      },
      this.resetFilters(amount, 0, title, !order)
    )
  }

  resetFilters(amount, page = 0, orderField = null, order = this.state.order) {
    const {
      template: { clientId, brands: templateBrands, jobCategories, clients, locationIds },
      availability,
      brands: clientBrands,
      fetchJobs,
      elasticLocale,
      parameters,
    } = this.props
    const { state, city, category, keywordsFilter } = this.state

    const clientIds = clients
      ? clients.map((client) => client.legacyClientId)
      : clientId && [clientId]
    const brands = templateBrands.length ? templateBrands : clientBrands.map((brand) => brand.name)

    let queryParams = parse(parameters)

    if (clientId) {
      fetchJobs(
        ElasticSearch.getSearchParameters(
          page,
          amount,
          clientIds,
          brands,
          locationIds,
          availability,
          keywordsFilter,
          category.length ? category : jobCategories,
          state,
          city,
          orderField,
          order || order === null ? 'asc' : 'desc',
          queryParams,
          jobCategories,
          category
        ),
        elasticLocale
      )
    }
  }

  submitSearch = (e) => {
    e.preventDefault()
    this.searchJobs()
  }

  handleSharingPopper = (event, currentJob) => {
    this.setState({
      anchorEl: this.state.anchorEl ? null : event.currentTarget,
      isSharingPopperOpened: !this.state.isSharingPopperOpened,
      currentJob: !this.state.isSharingPopperOpened ? currentJob : null,
    })
  }

  handleClickAway = () => {
    this.setState({
      anchorEl: null,
      isSharingPopperOpened: false,
    })
  }

  handleSelectOpen = (name) => () => {
    this.setState({
      [name + 'Open']: true,
    })
  }

  handleSelectClose = (name) => () => {
    this.setState({
      [name + 'Open']: false,
    })
  }

  render() {
    const {
      classes,
      id,
      jobSearchResults,
      analyticsParameters,
      locale,
      filters: { jobFilters, cityFilters },
      styles: {
        title,
        fontTitle,
        jobsList: {
          buttonApply,
          buttonShare,
          buttonSearch,
          columnNames,
          fontColumnHeader,
          fontResultList,
          customAttributeName,
          brandName,
          clientNameName,
        },
      },
      intl,
      templateId,
      templateType,
    } = this.props

    const {
      amount,
      state,
      city,
      category,
      keywordsFilter,
      page,
      order,
      orderTitle,
      currentJob,
      categoryOpen,
      stateOpen,
      cityOpen,
    } = this.state

    const columnsCount = columnNames.length
    const hasALotOfColumns = columnsCount > 3
    const widthMax = 100 / (columnsCount - 1) + '%'
    const columnWidthMin = 100 / (columnsCount + 1) + '%'
    const columnWidthMax = 100 / (columnsCount + 2) + '%'
    const columnWidth = hasALotOfColumns ? columnWidthMax : columnWidthMin
    const widthActionsRow = hasALotOfColumns ? widthMax : columnWidthMin
    const widthClientNameRow = 100 / columnsCount + '%'

    const invalidKeyword = keywordsFilter.split(' ').length > 5

    return (
      <SectionBackground id={id} color={this.getAsset('backgroundColor').hex}>
        <Article id="search-results-article">
          <header id="search-results-header">
            <SectionTitle id="search-results-header-title">
              <FontStyled
                id="search-results-header-title-font"
                fontFamily={this.getAsset('fontTitle.fontFamily', false).name}
                size={fontTitle && fontTitle.fontSize}
                color={this.getAsset('fontTitle.fontColor').hex}
              >
                {title}
              </FontStyled>
            </SectionTitle>
            <div className="center" id="search-results-header-subtitle">
              <FontStyled
                id="search-results-header-subtitle-font"
                fontFamily={this.getAsset('fontTitle.fontFamily', false).name}
                color={this.getAsset('fontTitle.fontColor').hex}
              >
                <FormattedMessage
                  id="careerSearch.searchResults.subtitle"
                  defaultMessage="For best results please search by city & state"
                />
              </FontStyled>
            </div>
          </header>
          <form name="jobSearch" onSubmit={this.submitSearch}>
            <FilterSection id="filters">
              <div id="filters-left">
                <span style={{ position: 'relative' }}>
                  <Input
                    title="Job / Keyword"
                    name="keywordsFilter"
                    type="text"
                    value={keywordsFilter}
                    className={classes.textInput}
                    onChange={this.handleSelectChange}
                    placeholder={intl.formatMessage({ id: 'careerSearch.searchPlaceholder' })}
                    id="filter-keywords"
                  />
                  {invalidKeyword && <Error>Keyword should be less than 5 words</Error>}
                </span>
                {jobFilters.categories && (
                  <Select
                    disableUnderline={true}
                    value={category}
                    name="category"
                    open={categoryOpen}
                    onChange={this.handleSelectChange}
                    onClose={this.handleSelectClose('category')}
                    onOpen={this.handleSelectOpen('category')}
                    displayEmpty
                    className={classes.select}
                    id="filter-category"
                    inputProps={{
                      multiple: true,
                      name: 'category',
                      id: 'category',
                    }}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return (
                          <em>
                            <FormattedMessage
                              id="careerSearch.category"
                              defaultMessage="Career Category"
                            />
                          </em>
                        )
                      }
                      return selected.join(', ')
                    }}
                  >
                    {sortBy(jobFilters.categories.buckets, 'key').map((currentCategory) => {
                      return (
                        <MenuItem key={currentCategory.key} value={currentCategory.key}>
                          <SelectOptionText>
                            <span>{currentCategory.key}</span>
                            {category.includes(currentCategory.key) && (
                              <span className="icon">✓</span>
                            )}
                          </SelectOptionText>
                        </MenuItem>
                      )
                    })}
                  </Select>
                )}
              </div>
              <div id="filters-right">
                {jobFilters.state && (
                  <Select
                    disableUnderline={true}
                    value={state}
                    open={stateOpen}
                    name="state"
                    onChange={this.handleSelectChange}
                    onClose={this.handleSelectClose('state')}
                    onOpen={this.handleSelectOpen('state')}
                    displayEmpty
                    className={classes.select}
                    id="filter-state"
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return (
                          <em>
                            <FormattedMessage id="careerSearch.state" defaultMessage="State" />
                          </em>
                        )
                      }
                      return selected.join(', ')
                    }}
                    inputProps={{
                      multiple: true,
                      name: 'state',
                      id: 'state',
                    }}
                  >
                    {sortBy(jobFilters.state.buckets, 'key').map((currentState) => {
                      return (
                        <MenuItem key={currentState.key} value={currentState.key}>
                          <SelectOptionText>
                            <span>{currentState.key}</span>
                            {state.includes(currentState.key) && <span className="icon">✓</span>}
                          </SelectOptionText>
                        </MenuItem>
                      )
                    })}
                  </Select>
                )}

                {jobFilters.city && (
                  <Select
                    disableUnderline={true}
                    value={city}
                    open={cityOpen}
                    name="city"
                    onChange={this.handleSelectChange}
                    onClose={this.handleSelectClose('city')}
                    onOpen={this.handleSelectOpen('city')}
                    displayEmpty
                    id="filter-city"
                    className={classes.select}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return (
                          <em>
                            <FormattedMessage id="careerSearch.city" defaultMessage="City" />
                          </em>
                        )
                      }
                      return selected.join(', ')
                    }}
                    inputProps={{
                      multiple: true,
                      name: 'city',
                      id: 'city',
                    }}
                  >
                    {sortBy(cityFilters.buckets, 'key').map((currentCity) => {
                      return (
                        <MenuItem key={currentCity.key} value={currentCity.key}>
                          <SelectOptionText>
                            <span>{currentCity.key}</span>
                            {city.includes(currentCity.key) && <span className="icon">✓</span>}
                          </SelectOptionText>
                        </MenuItem>
                      )
                    })}
                  </Select>
                )}
              </div>
            </FilterSection>
            <SearchButton
              id="search-button"
              color={this.getAsset('jobsList.buttonSearch.backgroundColor').hex}
              disabled={invalidKeyword}
              type="submit"
            >
              <FontStyled
                fontFamily={this.getAsset('jobsList.buttonSearch.font.fontFamily', false).name}
                size={buttonSearch && buttonSearch.font && buttonSearch.font.fontSize}
                color={this.getAsset('jobsList.buttonSearch.font.fontColor').hex}
                id="search-button-font"
              >
                <FormattedMessage id="careerSearch.searchButton" defaultMessage="Search" />
              </FontStyled>
            </SearchButton>
          </form>
          <section id="results-section">
            {!!jobSearchResults.total && (
              <header id="results-section-header">
                <h2>
                  <FontStyled
                    id="results-section-header-font"
                    fontFamily={this.getAsset('jobsList.fontColumnHeader.fontFamily', false).name}
                    color={this.getAsset('jobsList.fontColumnHeader.fontColor').hex}
                  >
                    <FormattedMessage id="careerSearch.results" defaultMessage="Results" />:{' '}
                    {jobSearchResults.total !== 1
                      ? jobSearchResults.total + intl.formatMessage({ id: 'careerSearch.jobs' })
                      : jobSearchResults.total + intl.formatMessage({ id: 'careerSearch.job' })}
                  </FontStyled>
                </h2>
              </header>
            )}
            {!jobSearchResults.total && jobSearchResults.hits && (
              <CenteredText id="results-section-no-results">
                <h3>
                  <FormattedMessage
                    id="careerSearch.noResult"
                    defaultMessage="We currently have no positions open in this category and location. Join our talent community below and receive updates as new positions become available."
                  />
                </h3>
              </CenteredText>
            )}
            {jobSearchResults && jobSearchResults.hits && (
              <Fragment>
                {!!jobSearchResults.total && (
                  <Table id="results-section-table">
                    <TableHead>
                      <HeaderRow>
                        {columnNames.includes('title') && (
                          <TableCell
                            className="mobile"
                            width={columnWidth}
                            id="title"
                            onClick={this.handleOrderChange('positionType.raw')}
                          >
                            <FontStyled
                              id="results-section-table-title-font"
                              fontFamily={
                                this.getAsset('jobsList.fontColumnHeader.fontFamily', false).name
                              }
                              size={fontColumnHeader && fontColumnHeader.fontSize}
                              color={this.getAsset('jobsList.fontColumnHeader.fontColor').hex}
                            >
                              <VerticalCenter>
                                <div>
                                  <FormattedMessage
                                    id="careerSearch.jobTitle"
                                    defaultMessage="Job Title"
                                  />{' '}
                                  <AddressTableHead>
                                    {columnNames.includes('location') && (
                                      <FormattedMessage
                                        id="careerSearch.andAddress"
                                        defaultMessage="& Address"
                                      />
                                    )}
                                  </AddressTableHead>
                                </div>
                                <OrderIcon
                                  arrow={orderTitle === 'positionType.raw' ? order : null}
                                />
                              </VerticalCenter>
                            </FontStyled>
                          </TableCell>
                        )}
                        {columnNames.includes('customAttribute') && (
                          <TableCell
                            width={columnWidth}
                            id="customAttribute"
                            onClick={this.handleOrderChange('socialRecruitingAttribute1.keyword')}
                            className="no-mobile second-cell"
                          >
                            <FontStyled
                              id="results-section-table-customAttribute-font"
                              fontFamily={
                                this.getAsset('jobsList.fontColumnHeader.fontFamily', false).name
                              }
                              size={fontColumnHeader && fontColumnHeader.fontSize}
                              color={this.getAsset('jobsList.fontColumnHeader.fontColor').hex}
                            >
                              <VerticalCenter>
                                {customAttributeName ||
                                  intl.formatMessage({ id: 'careerSearch.customAttribute' })}
                                <OrderIcon
                                  arrow={
                                    orderTitle === 'socialRecruitingAttribute1.keyword'
                                      ? order
                                      : null
                                  }
                                />
                              </VerticalCenter>
                            </FontStyled>
                          </TableCell>
                        )}
                        {columnNames.includes('brand') && (
                          <TableCell
                            width={columnWidth}
                            id="brand"
                            onClick={this.handleOrderChange('brand.raw')}
                            className="no-mobile"
                          >
                            <FontStyled
                              id="results-section-table-brand-font"
                              fontFamily={
                                this.getAsset('jobsList.fontColumnHeader.fontFamily', false).name
                              }
                              size={fontColumnHeader && fontColumnHeader.fontSize}
                              color={this.getAsset('jobsList.fontColumnHeader.fontColor').hex}
                            >
                              <VerticalCenter>
                                {brandName || intl.formatMessage({ id: 'careerSearch.brand' })}
                                <OrderIcon arrow={orderTitle === 'brand.raw' ? order : null} />
                              </VerticalCenter>
                            </FontStyled>
                          </TableCell>
                        )}
                        {columnNames.includes('clientName') && (
                          <TableCell
                            width={widthClientNameRow}
                            id="clientName"
                            onClick={this.handleOrderChange('clientName.raw')}
                            className="no-mobile"
                          >
                            <FontStyled
                              id="results-section-table-clientName-font"
                              fontFamily={
                                this.getAsset('jobsList.fontColumnHeader.fontFamily', false).name
                              }
                              size={fontColumnHeader && fontColumnHeader.fontSize}
                              color={this.getAsset('jobsList.fontColumnHeader.fontColor').hex}
                            >
                              <VerticalCenter>
                                {clientNameName ||
                                  intl.formatMessage({ id: 'careerSearch.clientName' })}
                                <OrderIcon arrow={orderTitle === 'clientName.raw' ? order : null} />
                              </VerticalCenter>
                            </FontStyled>
                          </TableCell>
                        )}
                        {columnNames.includes('location') && (
                          <TableCell
                            width={columnWidth}
                            id="city"
                            onClick={this.handleOrderChange('address.postalCode.raw')}
                            className="no-mobile"
                          >
                            <FontStyled
                              id="results-section-table-city-font"
                              fontFamily={
                                this.getAsset('jobsList.fontColumnHeader.fontFamily', false).name
                              }
                              size={fontColumnHeader && fontColumnHeader.fontSize}
                              color={this.getAsset('jobsList.fontColumnHeader.fontColor').hex}
                            >
                              <VerticalCenter>
                                <FormattedMessage
                                  id="careerSearch.address"
                                  defaultMessage="Address"
                                />{' '}
                                <OrderIcon
                                  arrow={orderTitle === 'address.postalCode.raw' ? order : null}
                                />
                              </VerticalCenter>
                            </FontStyled>
                          </TableCell>
                        )}
                        <TableCell
                          style={{ padding: 0 }}
                          className="mobile"
                          width={widthActionsRow}
                        >
                          <FontStyled
                            id="results-section-table-take-action-font"
                            fontFamily={
                              this.getAsset('jobsList.fontColumnHeader.fontFamily', false).name
                            }
                            size={fontColumnHeader && fontColumnHeader.fontSize}
                            color={this.getAsset('jobsList.fontColumnHeader.fontColor').hex}
                          >
                            <LastHeaderCell>
                              <FormattedMessage
                                id="careerSearch.actionButtons"
                                defaultMessage="Take Action"
                              />
                            </LastHeaderCell>
                          </FontStyled>
                        </TableCell>
                      </HeaderRow>
                    </TableHead>
                    <TableBody>
                      {jobSearchResults.hits.map((job) => {
                        const jobInfo = job._source
                        const jobsListColor = this.getAsset('jobsList.fontResultList.fontColor').hex
                        return (
                          <TableRow key={jobInfo.jobId} color={jobsListColor}>
                            {columnNames.includes('title') && (
                              <TableCell className="mobile" width={columnWidth}>
                                <FontStyled
                                  fontFamily={
                                    this.getAsset('jobsList.fontResultList.fontFamily', false).name
                                  }
                                  size={fontResultList && fontResultList.fontSize}
                                  color={jobsListColor}
                                >
                                  <TextLink
                                    data-test-id="apply"
                                    target="_blank"
                                    href={`/clients/${jobInfo.clientId}/posting/${jobInfo.jobId}/${locale}${analyticsParameters}`}
                                  >
                                    {jobInfo.positionType} <br />
                                  </TextLink>
                                  {columnNames.includes('location') && (
                                    <Location>
                                      {jobInfo.address.street1} <br />
                                      {`${jobInfo.address.city},
                                          ${jobInfo.address.country},
                                          ${jobInfo.address.stateOrProvince},
                                        ${jobInfo.address.postalCode}
                                      `}
                                    </Location>
                                  )}
                                </FontStyled>
                              </TableCell>
                            )}
                            {columnNames.includes('customAttribute') && (
                              <TableCell width={columnWidth} className="no-mobile second-cell">
                                <FontStyled
                                  fontFamily={
                                    this.getAsset('jobsList.fontResultList.fontFamily', false).name
                                  }
                                  size={fontResultList && fontResultList.fontSize}
                                  color={jobsListColor}
                                >
                                  {jobInfo.socialRecruitingAttribute1}
                                </FontStyled>
                              </TableCell>
                            )}
                            {columnNames.includes('brand') && (
                              <TableCell width={columnWidth} className="no-mobile">
                                <FontStyled
                                  fontFamily={
                                    this.getAsset('jobsList.fontResultList.fontFamily', false).name
                                  }
                                  size={fontResultList && fontResultList.fontSize}
                                  color={jobsListColor}
                                >
                                  {jobInfo.brand}
                                </FontStyled>
                              </TableCell>
                            )}
                            {columnNames.includes('clientName') && (
                              <TableCell width={widthClientNameRow} className="no-mobile">
                                <FontStyled
                                  fontFamily={
                                    this.getAsset('jobsList.fontResultList.fontFamily', false).name
                                  }
                                  size={fontResultList && fontResultList.fontSize}
                                  color={jobsListColor}
                                >
                                  {jobInfo.clientName}
                                </FontStyled>
                              </TableCell>
                            )}
                            {columnNames.includes('location') && (
                              <TableCell width={columnWidth} className="no-mobile">
                                <FontStyled
                                  fontFamily={
                                    this.getAsset('jobsList.fontResultList.fontFamily', false).name
                                  }
                                  size={fontResultList && fontResultList.fontSize}
                                  color={jobsListColor}
                                >
                                  {jobInfo.address.street1} <br />
                                  {`${jobInfo.address.city},
                                ${jobInfo.address.country},
                                ${jobInfo.address.stateOrProvince},
                                ${jobInfo.address.postalCode}
                              `}
                                </FontStyled>
                              </TableCell>
                            )}
                            <TableCell className="mobile" width={widthActionsRow}>
                              <ButtonsCell>
                                <LinkButton
                                  data-test-id="apply"
                                  color={this.getAsset('jobsList.buttonApply.backgroundColor').hex}
                                  target="_blank"
                                  href={`/clients/${jobInfo.clientId}/posting/${jobInfo.jobId}/${locale}${analyticsParameters}`}
                                >
                                  <FontStyled
                                    style={{ margin: '0 auto' }}
                                    fontFamily={
                                      this.getAsset('jobsList.buttonApply.font.fontFamily', false)
                                        .name
                                    }
                                    size={
                                      buttonApply && buttonApply.font && buttonApply.font.fontSize
                                    }
                                    color={this.getAsset('jobsList.buttonApply.font.fontColor').hex}
                                  >
                                    <FormattedMessage
                                      id="careerSearch.applyButton"
                                      defaultMessage="Apply"
                                    />
                                  </FontStyled>
                                </LinkButton>
                                <ClickAwayListener onClickAway={this.handleClickAway}>
                                  <Fragment>
                                    <Button
                                      data-test-id="share"
                                      color={
                                        this.getAsset('jobsList.buttonShare.backgroundColor').hex
                                      }
                                      onClick={(e) => this.handleSharingPopper(e, jobInfo)}
                                    >
                                      <FontStyled
                                        style={{ margin: '0 auto' }}
                                        fontFamily={
                                          this.getAsset(
                                            'jobsList.buttonShare.font.fontFamily',
                                            false
                                          ).name
                                        }
                                        size={
                                          buttonShare &&
                                          buttonShare.font &&
                                          buttonShare.font.fontSize
                                        }
                                        color={
                                          this.getAsset('jobsList.buttonShare.font.fontColor').hex
                                        }
                                      >
                                        <FormattedMessage
                                          id="general.share"
                                          defaultMessage="Share"
                                        />
                                      </FontStyled>
                                    </Button>
                                    <SharingPopper
                                      open={this.state.isSharingPopperOpened}
                                      anchorEl={this.state.anchorEl}
                                      transition
                                    >
                                      {({ TransitionProps }) => (
                                        <Fade {...TransitionProps} timeout={100}>
                                          <SharingContainer>
                                            <SharingTitle>
                                              <FontStyled
                                                fontFamily={
                                                  this.getAsset(
                                                    'jobsList.buttonShare.font.fontFamily',
                                                    false
                                                  ).name
                                                }
                                                size={
                                                  buttonShare &&
                                                  buttonShare.font &&
                                                  buttonShare.font.fontSize
                                                }
                                                color={
                                                  this.getAsset(
                                                    'jobsList.buttonShare.font.fontColor'
                                                  ).hex
                                                }
                                              >
                                                <FormattedMessage
                                                  id="share.job"
                                                  defaultMessage="Share job"
                                                />
                                              </FontStyled>
                                            </SharingTitle>
                                            <ShareButtons
                                              clientName={currentJob.clientName}
                                              clientId={currentJob.clientId}
                                              jobId={currentJob.jobId}
                                              locale={locale}
                                              positionType={currentJob.positionType}
                                              positionLocation={`${currentJob.address.street1}, ${currentJob.address.city}, ${currentJob.address.country}, ${currentJob.address.stateOrProvince}, ${currentJob.address.postalCode}`}
                                              templateId={templateId}
                                              templateType={templateType}
                                            />
                                          </SharingContainer>
                                        </Fade>
                                      )}
                                    </SharingPopper>
                                  </Fragment>
                                </ClickAwayListener>
                              </ButtonsCell>
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                )}
                <PaginationWrapper
                  id="results-section-pagination"
                  color={this.getAsset('jobsList.colorPaging').hex}
                >
                  <Pagination
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleAmountChange}
                    options={{
                      totalRows: jobSearchResults.total,
                      rowsPerPage: amount,
                      page: page + 1,
                      rowsPerPageOptions: [10, 25, 50, 100],
                      visiblePageButtonsCount: 3,
                    }}
                  />
                </PaginationWrapper>
              </Fragment>
            )}
          </section>
        </Article>
      </SectionBackground>
    )
  }
}

const mapStateToProps = ({
  brands,
  assetsCollection,
  jobSearchResults,
  filters,
  user: { analyticsParameters },
  template: { type },
}) => ({
  assetsCollection: assetsCollection.assets,
  filters,
  brands: brands.brands,
  brandsState: brands,
  jobSearchResults: jobSearchResults.jobSearchResults,
  analyticsParameters,
  templateType: type,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchAsset,
      fetchBrands,
      fetchJobs,
      reserveAssetKey,
      fetchJobFilters,
      fetchCitiesFilter,
    },
    dispatch
  )

const StyledSearchResults = withStyles(styles)(SearchResults)

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(StyledSearchResults))
