export const CALLAPI = 'middleware/CALLAPI'

export default function callAPIMiddleware({ dispatch, getState }) {
  return (next) => (action) => {
    const { type, types, callAPI, shouldCallAPI = () => true, payload, retry } = action

    if (type !== CALLAPI || !types) {
      // Normal action: pass it on
      return next(action)
    }

    if (
      !Array.isArray(types) ||
      types.length !== 3 ||
      !types.every((type) => typeof type === 'string')
    ) {
      throw new Error('Expected an array of three string types.')
    }

    if (typeof callAPI !== 'function') {
      throw new Error('Expected callAPI to be a function.')
    }

    if (!shouldCallAPI(getState())) {
      return
    }

    const [requestType, successType, failureType] = types

    dispatch({ payload, type: requestType })

    var errorResponse = (err) => dispatch({ payload: err, type: failureType })

    const retryDelayInMilliseconds = 5000
    var retryCount = 3 // retriying 3 times in case the posting takes a bit to get to ES

    var successReponse = (resp) => {
      if (resp.status === 204) {
        // For SEO purposes we are replacing 404s with 204s so the searh engines don't consider this a "Soft 404", we treat 204s ar errors though for appropriate behaviour downstream
        return dispatch({ payload: {}, type: failureType })
      }
      if (retry && retry(resp.data) && retryCount-- > 0) {
        setTimeout(function () {
          callAPI().then(successReponse).catch(errorResponse)
        }, retryDelayInMilliseconds)
      } else {
        return dispatch({ payload: resp.data, type: successType })
      }
    }

    return callAPI().then(successReponse).catch(errorResponse)
  }
}
