import axios from 'axios'
import { CALLAPI } from '../middleware/callAPIMiddleware'
import { apiMarketingAssetsURL } from '../../config/endpoints'

const ADD_ASSET = 'ADD_ASSET'
const FETCH_ASSET = 'FETCH_ASSET'
const FETCH_ASSET_SUCCESS = 'FETCH_ASSET_SUCCESS'
const FETCH_ASSET_FAIL = 'FETCH_ASSET_FAIL'
const RESERVE_ASSET = 'RESERVE_ASSET'

const initialState = {
  assets: {},
  error: null,
  loading: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_ASSET:
      return {
        ...state,
        assets: { ...state.assets, [action.payload.id]: action.payload },
      }
    case FETCH_ASSET:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case FETCH_ASSET_SUCCESS:
      return {
        ...state,
        assets: { ...state.assets, [action.payload.id]: action.payload },
        error: null,
        loading: false,
      }
    case RESERVE_ASSET:
      return {
        ...state,
        assets: { ...state.assets, [action.payload]: {} },
        error: null,
        loading: false,
      }
    case FETCH_ASSET_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

export function addAssetToCollection(asset) {
  return {
    type: ADD_ASSET,
    payload: asset,
  }
}

export function reserveAssetKey(key) {
  return {
    type: RESERVE_ASSET,
    payload: key,
  }
}

export function fetchAsset(clientId, assetId) {
  const url = `${apiMarketingAssetsURL}clients/${clientId}/assets`
  return {
    type: CALLAPI,
    types: [FETCH_ASSET, FETCH_ASSET_SUCCESS, FETCH_ASSET_FAIL],
    callAPI: () => axios.get(`${url}/${assetId}`),
  }
}
