const SET_CLIENT_ID = 'user/SET_CLIENT_ID'
const SET_ANALYTICS_PARAMETERS = 'user/SET_ANALYTICS_PARAMETERS'

const initialState = {
  clientId: null,
  analyticsParameters: '?param=no', // Need to default one param because we have custom code on the templates that will just add &internalStatus=true to the end
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CLIENT_ID:
      return {
        ...state,
        clientId: action.clientId,
      }
    case SET_ANALYTICS_PARAMETERS:
      return {
        ...state,
        analyticsParameters: action.analyticsParameters,
      }
    default:
      return state
  }
}

export function setClientId(clientId) {
  return {
    type: SET_CLIENT_ID,
    clientId: parseInt(clientId, 10),
  }
}

export function setAnalyticsParameters(analyticsParameters) {
  return {
    type: SET_ANALYTICS_PARAMETERS,
    analyticsParameters: analyticsParameters,
  }
}
