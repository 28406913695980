import axios from 'axios'
import { CALLAPI } from '../middleware/callAPIMiddleware'
import { elasticSearchURL } from '../../config/endpoints'

const FETCH_JOB_FILTERS = 'FETCH_JOB_FILTERS'
const FETCH_JOB_FILTERS_SUCCESS = 'FETCH_JOB_FILTERS_SUCCESS'
const FETCH_JOB_FILTERS_FAIL = 'FETCH_JOB_FILTERS_FAIL'
const FETCH_CITIES_FILTER = 'FETCH_CITIES_FILTER'
const FETCH_CITIES_FILTER_SUCCESS = 'FETCH_CITIES_FILTER_SUCCESS'
const FETCH_CITIES_FILTER_FAIL = 'FETCH_CITIES_FILTER_FAIL'
const RESET_JOB_FILTERS = 'RESET_JOB_FILTERS'

const initialState = {
  jobFilters: {},
  cityFilters: {},
  error: null,
  loading: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_JOB_FILTERS:
      return {
        ...initialState,
      }
    case FETCH_JOB_FILTERS:
      return {
        ...state,
        jobFilters: {},
        loading: true,
      }
    case FETCH_JOB_FILTERS_SUCCESS:
      return {
        ...state,
        jobFilters: action.payload.aggregations,
      }
    case FETCH_JOB_FILTERS_FAIL:
      return {
        ...state,
        jobFilters: {},
        error: action.payload,
      }
    case FETCH_CITIES_FILTER:
      return {
        ...state,
        cityFilters: {},
        loading: true,
      }
    case FETCH_CITIES_FILTER_SUCCESS:
      return {
        ...state,
        cityFilters: action.payload.aggregations.city,
        loading: true,
      }
    case FETCH_CITIES_FILTER_FAIL:
      return {
        ...state,
        cityFilters: {},
        error: action.payload,
      }
    default:
      return state
  }
}

export function fetchJobFilters(elasticParameters, elasticIndex) {
  return {
    type: CALLAPI,
    types: [FETCH_JOB_FILTERS, FETCH_JOB_FILTERS_SUCCESS, FETCH_JOB_FILTERS_FAIL],
    callAPI: () => axios.post(elasticSearchURL(elasticIndex), elasticParameters),
  }
}

export function fetchCitiesFilter(elasticParameters, elasticIndex) {
  return {
    type: CALLAPI,
    types: [FETCH_CITIES_FILTER, FETCH_CITIES_FILTER_SUCCESS, FETCH_CITIES_FILTER_FAIL],
    callAPI: () => axios.post(elasticSearchURL(elasticIndex), elasticParameters),
  }
}
