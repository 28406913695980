import React from 'react'

import axios from 'axios'
import { parsePhoneNumber } from 'libphonenumber-js'
import sortBy from 'lodash/sortBy'
import { Form, Field as FinalField } from 'react-final-form'
import { FormattedMessage, injectIntl } from 'react-intl'
import IntlTelInput from 'react-intl-tel-input'
import styled from 'styled-components'
import { EmailValidator } from 'commons-validator-js'

import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'

import { Colors } from 'tr-components/Colors'
import { NotificationDialog as StylessNotificationDialog } from 'tr-components/NotificationDialog'
import { Select as UnstyledSelect } from 'tr-components/Select'
import { TextField as UnstyledTextField } from 'tr-components/TextField'
import { Checkbox } from 'tr-components/Checkbox'

import { apiURLDomain } from '../../config/endpoints'

const SELECTS_LIMIT = 10

const Field = styled(UnstyledTextField)`
  & > div {
    border: 0;
    margin: 0;
    border-radius: 4px;
    background-color: ${Colors.white};
    & > div {
      &:before {
        left: 2px;
        right: 2px;
        bottom: 1px;
      }
      &:after {
        left: 2px;
        right: 2px;
        bottom: 1px;
      }
    }
    input {
      border-radius: 4px;
      border: 1px solid ${Colors.gray};
      width: 100%;
      display: block;
      height: 40px;
      padding: 0 10px;
      font-size: 16px;
      background-color: ${Colors.white};
      &:after {
        content: '';
      }
      &:before {
        content: '';
      }
    }
  }
`
const NotificationDialog = styled(StylessNotificationDialog)`
  button {
    color: ${(p) => p.fontColor};
    background-color: ${(p) => p.color};
    font-family: ${(p) => p.fontFamily}, sans-serif;
    &:hover {
      background-color: ${(p) => p.color};
      filter: brightness(80%);
    }
  }
`
const Select = styled(UnstyledSelect)`
  && {
    border-radius: 4px;
    border: 1px solid ${Colors.gray};
    & > h3 {
      margin-top: 3px;
      @media screen and (max-width: 680px) {
        font-size: 0.75rem;
      }
    }
  }
`
const Error = styled.div`
  color: #e18e8f;
  font-size: 0.75rem;
  position: absolute;
`

const SelectedContainer = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
`

const JoinButton = styled.button`
  margin: 20px auto;
  padding: 14px;
  text-transform: uppercase;
  font-size: 18px;
  border-radius: 3px;
  background-color: ${(p) => p.color || 'black'};
  display: block;
  color: white;
  border: none;
  font-weight: bold;
  cursor: pointer;
  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

const FontStyled = styled.div`
  display: inline-block;
  font-family: ${(font) => font.fontFamily}, sans-serif;
  font-size: ${(font) => (font.size ? `${font.size}px` : 'inherit')};
  color: ${(font) => font.color};
`

const ColumnHolder = styled.div`
  & > div {
    position: relative;
    margin-bottom: 1.2rem;
    p,
    svg {
      position: absolute;
    }
    p {
      left: 0;
      top: 3.5em;
    }
    svg {
      top: 0.15em;
    }
  }
  .upload-resume-container {
    margin-top: 15px;
  }
`

const FieldsWrapper = styled.div`
  max-width: 950px;
  display: flex;
  margin: 0 auto;
  flex-direction: row;
  justify-content: space-between;
  text-align: left;
  color: ${(p) => p.color};
  @media screen and (max-width: 680px) {
    display: block;
  }

  & > div {
    width: 50%;
    padding: 10px;
    box-sizing: border-box;
    @media screen and (max-width: 680px) {
      width: 100%;
      padding: 0;
    }
  }
`

const IntlTelInputWrapper = styled.div`
  .intl-tel-input {
    width: 100%;
    height: 42px;
    margin: 16px 0 0;

    input {
      width: 100%;
      height: 42px;
      border: 1px solid ${Colors.gray};
      border-radius: 3px;
      font-size: 16px;
      background-color: white;
      box-sizing: border-box;
      padding: 5px 10px;
    }
  }
`

const Warning = styled.span`
  color: ${Colors.errorRed};
  align-self: center;
  font-size: 0.8em;
  width: 100%;
  text-align: center;
`

const CheckBoxContainer = styled.div`
  display: flex;
  justify-content: center;
`

const StyledLabel = styled.div`
  font-family: ${(font) => font.fontFamily}, sans-serif;
  color: ${(font) => font.color};
`

const selectStyles = {
  textAlign: 'left',
  width: '100%',
  height: '42px',
  backgroundColor: 'white',
  fontStyle: 'normal',
  margin: '16px 0 10px',
  padding: '6px 10px',
  borderBottom: `1px solid ${Colors.darkGray}`,
}

const styles = () => ({
  button: {
    backgroundColor: Colors.darkBlue,
    '&:hover': {
      backgroundColor: Colors.darkBlue,
    },
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formControl: {
    minWidth: '100%',
  },
  select: selectStyles,
  selectPlaceholder: {
    ...selectStyles,
    '& > div': {
      marginTop: '-25px',
      '&:after': {
        background: 'transparent',
      },
    },
  },
})

const limitError = <span style={{ color: Colors.red }}>You can only add up to 10 options</span>

class JoinTalentCommunityForm extends React.Component {
  state = {
    submitSuccess: false,
    modal: false,
    outOfLimitOption: {},
  }

  onCancel = () => {
    this.setState({
      modal: false,
      submitError: null,
    })
  }

  submitForm = (values) => {
    const {
      resume,
      jobCategories,
      desiredLocations,
      phoneWithCode,
      brandIds,
      notifyBy,
      ...personInfo
    } = values
    const {
      analyticsParameters,
      clientId,
      templateId,
      allBrands,
      brands,
      availability,
      templateType,
      locale,
    } = this.props

    const selectedBrands =
      brands.length === 1
        ? allBrands.filter((brand) => brands.includes(brand.name)).map((brand) => brand.id)
        : allBrands.filter((brand) => brandIds.includes(brand.name)).map((brand) => brand.id)

    personInfo['phone'] = phoneWithCode
    personInfo['email'] = personInfo.email.trim()

    let data = new FormData()
    data.append('cvFile', resume)
    data.append(
      'formData',
      JSON.stringify({
        ...personInfo,
        locale,
        desiredBrandIds: selectedBrands,
        sendUpdatesViaEmail: notifyBy.includes('Email'),
        sendUpdatesViaText: notifyBy.includes('Text'),
        desiredLocations,
        desiredJobCategories: jobCategories,
        clientId,
        sourceType:
          templateType === 'CareerPage'
            ? 'CAREER_PAGE_JOIN_TALENT_COMMUNITY_FORM'
            : 'JOB_PAGE_JOIN_TALENT_COMMUNITY_FORM',
      })
    )

    const internal = `internal=${availability === 'INTERNAL'}`
    axios
      .post(
        `${apiURLDomain}clients/${clientId}/recruit/public/members${analyticsParameters}&originId=${templateId}&${internal}`,
        data
      )
      .then(
        () => {
          this.setState({
            submitSuccess: true,
            modal: true,
            values: null,
          })
        },
        (error) => {
          this.setState({
            submitError: error.response.data.validationFailures,
            modal: true,
            values,
          })
        }
      )
  }

  validateForm = (values) => {
    const errors = {}
    const { intl, brands } = this.props
    const emailValidator = new EmailValidator()

    try {
      const phoneNumber = values.phoneWithCode && parsePhoneNumber(values.phoneWithCode)
      if (values.phone && !phoneNumber.isValid()) {
        errors.phone = intl.formatMessage({ id: 'validation.phone' })
      }
    } catch (error) {
      errors.phone = intl.formatMessage({ id: 'validation.phone' })
      console.error(error)
    }

    if (!values.firstName) {
      errors.firstName = intl.formatMessage({ id: 'validation.firstName' })
    }
    if (!values.lastName) {
      errors.lastName = intl.formatMessage({ id: 'validation.lastName' })
    }
    if (!values.phone) {
      errors.phone = intl.formatMessage({ id: 'validation.share.phone' })
    }
    if (!values.email || !emailValidator.isValid(values.email)) {
      errors.email = intl.formatMessage({ id: 'validation.email' })
    }
    if (values.jobCategories && !values.jobCategories.length) {
      errors.jobCategories = intl.formatMessage({ id: 'validation.career' })
    }
    if (values.desiredLocations && !values.desiredLocations.length) {
      errors.desiredLocations = intl.formatMessage({ id: 'validation.location' })
    }
    if (brands.length !== 1 && values.brandIds && !values.brandIds.length) {
      errors.brandIds = intl.formatMessage({ id: 'validation.brand' })
    }
    if (values.resume && values.resume.size / 1024 / 1024 > 50) {
      errors.resume = `File ${values.resume.name} exceeds 50MB size limit`
    }
    return errors
  }

  selectChange = (values, data, change) => (event) => {
    const { value, name } = event.target
    const { outOfLimitOption } = this.state
    const lengthIsValid = value.length <= SELECTS_LIMIT

    if (lengthIsValid) {
      this.setState(
        {
          outOfLimitOption: {
            ...outOfLimitOption,
            [name]: null,
          },
          values,
        },
        () => change(name, value)
      )
    } else {
      this.setState(
        {
          outOfLimitOption: {
            ...outOfLimitOption,
            [name]: value[SELECTS_LIMIT],
          },
          values,
        },
        () => change(name, value.slice(0, SELECTS_LIMIT))
      )
    }
  }

  render() {
    const {
      getAsset,
      button: { font },
      categories,
      classes,
      locations,
      brands,
      color,
      titleFontFamily,
      clientName,
      intl,
    } = this.props
    const { modal, submitError, values, outOfLimitOption } = this.state

    const joinButtonPath = 'joinTalentCommunity.buttonJoin'

    const phoneField = (form) => (
      <div>
        <div style={{ display: 'none' }}>
          <Field name="phoneWithCode" id="phone-code" />
        </div>

        <FinalField
          name="phone"
          id="talent-community-phone"
          render={({ input, meta }) => (
            <IntlTelInputWrapper>
              <IntlTelInput
                telInputProps={{
                  title: 'Phone',
                }}
                {...input}
                nationalMode={false}
                defaultCountry="us"
                useMobileFullscreenDropdown={true}
                preferredCountries={['us']}
                format={true}
                onPhoneNumberChange={(status, value, countryData, phone) => {
                  form.change('phoneWithCode', phone)
                  form.change('phone', value)
                }}
                css={['intl-tel-input']}
              />
              <Error>{meta.error && meta.dirty && <span>{meta.error}</span>}</Error>
            </IntlTelInputWrapper>
          )}
        />
      </div>
    )

    const sortedCategories = categories && [
      ...sortBy(categories.buckets, 'key').map((category) => category.key),
    ]

    const addresses = []
    const uniqueLocations =
      locations &&
      locations.filter((location) => {
        const { country, state, city } = location
        const address = `${country}, ${state}, ${city}`
        if (!addresses.includes(address)) {
          addresses.push(address)
          return true
        } else {
          return false
        }
      })

    const sortedLocations = uniqueLocations && sortBy(uniqueLocations, ['country', 'state', 'city'])

    return (
      <div>
        <div>
          <Form
            id="talent-community-form"
            initialValues={
              values
                ? values
                : { jobCategories: [], desiredLocations: [], brandIds: [], notifyBy: ['Email'] }
            }
            onSubmit={this.submitForm}
            validate={this.validateForm}
            render={({ handleSubmit, values, invalid, form, form: { change } }) => {
              const isBrandsSelectVisible = brands.length > 1

              return (
                <div>
                  <form onSubmit={handleSubmit} name="joinTalentCommunity">
                    <FieldsWrapper color={color}>
                      <ColumnHolder>
                        <Field
                          name="firstName"
                          id="talent-community-firstName"
                          placeholder={intl.formatMessage({ id: 'general.firstName' })}
                          inputProps={{
                            maxLength: '40',
                            title: intl.formatMessage({ id: 'general.firstName' }),
                          }}
                          fullWidth
                        />
                        <Field
                          name="lastName"
                          placeholder={intl.formatMessage({ id: 'general.lastName' })}
                          id="talent-community-lastName"
                          inputProps={{
                            maxLength: '40',
                            title: intl.formatMessage({ id: 'general.lastName' }),
                          }}
                          fullWidth
                        />
                        <Field
                          name="email"
                          placeholder={intl.formatMessage({ id: 'general.email' })}
                          id="talent-community-email"
                          inputProps={{
                            maxLength: '254',
                            title: intl.formatMessage({ id: 'general.email' }),
                          }}
                          fullWidth
                        />
                        {isBrandsSelectVisible && phoneField(form)}
                      </ColumnHolder>
                      <ColumnHolder>
                        {!isBrandsSelectVisible && phoneField(form)}
                        {categories && (
                          <Select
                            name="jobCategories"
                            disableUnderline={true}
                            id="talent-community-jobCategories"
                            aria-label="Select Career Interest(s)"
                            inputProps={{
                              multiple: true,
                              name: 'jobCategories',
                              id: 'jobCategories',
                              onChange: this.selectChange(values, sortedCategories, change),
                            }}
                            className={
                              values['jobCategories'].length
                                ? classes.select
                                : classes.selectPlaceholder
                            }
                            renderValue={(selected) => {
                              const { length } = selected
                              return (
                                <SelectedContainer>
                                  {selected.map((value, index) => {
                                    return (
                                      <span key={value}>
                                        {value}
                                        {length - 1 > index && ', '}
                                      </span>
                                    )
                                  })}
                                </SelectedContainer>
                              )
                            }}
                            label={
                              !values['jobCategories'].length &&
                              intl.formatMessage({ id: 'talentCommunity.jobCategories' })
                            }
                          >
                            {sortBy(categories.buckets, 'key').map((category, index) => {
                              return (
                                <MenuItem
                                  className={classes.menuItem}
                                  key={index}
                                  value={category.key}
                                >
                                  <span>{category.key}</span>
                                  {outOfLimitOption.jobCategories === category.key && limitError}
                                </MenuItem>
                              )
                            })}
                          </Select>
                        )}
                        {locations && (
                          <Select
                            name="desiredLocations"
                            disableUnderline={true}
                            id="talent-community-desiredLocations"
                            aria-label="Select Desired Location(s)"
                            inputProps={{
                              multiple: true,
                              name: 'desiredLocations',
                              id: 'desiredLocations',
                              onChange: this.selectChange(values, sortedLocations, change),
                            }}
                            label={
                              !values['desiredLocations'].length &&
                              intl.formatMessage({ id: 'talentCommunity.desiredLocations' })
                            }
                            renderValue={(selected) => {
                              const { length } = selected
                              return (
                                <SelectedContainer>
                                  {selected.map(({ country, state, city }, index) => {
                                    return (
                                      <span key={country + state + city}>
                                        {country}, {state}, {city}
                                        {length - 1 > index && ', '}
                                      </span>
                                    )
                                  })}
                                </SelectedContainer>
                              )
                            }}
                            className={
                              values['desiredLocations'].length
                                ? classes.select
                                : classes.selectPlaceholder
                            }
                          >
                            {sortBy(locations, ['country', 'state', 'city']).map(
                              (location, index) => {
                                return (
                                  <MenuItem
                                    key={index}
                                    className={classes.menuItem}
                                    value={location}
                                  >
                                    {`${location.country}, ${location.state}, ${location.city}`}
                                    {outOfLimitOption.desiredLocations === location
                                      ? limitError
                                      : ''}
                                  </MenuItem>
                                )
                              }
                            )}
                          </Select>
                        )}
                        {isBrandsSelectVisible && (
                          <Select
                            name="brandIds"
                            disableUnderline={true}
                            id="talent-community-desiredBrands"
                            aria-label="Select Desired Brand(s)"
                            inputProps={{
                              multiple: true,
                              name: 'brandIds',
                              id: 'brandIds',
                              onChange: this.selectChange(values, brands, change),
                            }}
                            label={
                              !values['brandIds'].length &&
                              intl.formatMessage({ id: 'talentCommunity.desiredBrands' })
                            }
                            renderValue={(selected) => {
                              const { length } = selected
                              return (
                                <SelectedContainer>
                                  {selected.map((value, index) => {
                                    return (
                                      <span key={value}>
                                        {value}
                                        {length - 1 > index && ', '}
                                      </span>
                                    )
                                  })}
                                </SelectedContainer>
                              )
                            }}
                            className={
                              values['brandIds'].length ? classes.select : classes.selectPlaceholder
                            }
                          >
                            {brands.map((brand, index) => {
                              return (
                                <MenuItem key={index} className={classes.menuItem} value={brand}>
                                  <span>{brand}</span>
                                  {outOfLimitOption.brandIds === brand && limitError}
                                </MenuItem>
                              )
                            })}
                          </Select>
                        )}
                        <FinalField
                          name="resume"
                          id="talent-community-resume"
                          render={({ meta }) => (
                            <div className="upload-resume-container">
                              <label>
                                <FormattedMessage
                                  id="talentCommunity.resume"
                                  defaultMessage="Upload Resume: "
                                />
                              </label>
                              <input
                                title="Choose File"
                                type="file"
                                onChange={(event) => {
                                  form.change('resume', event.target.files[0])
                                }}
                              />
                              <Error>{meta.error && <span>{meta.error}</span>}</Error>
                            </div>
                          )}
                        />
                      </ColumnHolder>
                    </FieldsWrapper>
                    <div style={{ textAlign: 'center' }}>
                      <StyledLabel color={color} fontFamily={titleFontFamily}>
                        <p>{intl.formatMessage({ id: 'general.communicationType' })}</p>
                      </StyledLabel>
                      <CheckBoxContainer>
                        <Checkbox
                          name="notifyBy"
                          label={
                            <StyledLabel color={color} fontFamily={titleFontFamily}>
                              {intl.formatMessage({ id: 'general.email' })}
                            </StyledLabel>
                          }
                          value="Email"
                          disabled={
                            values.notifyBy.length === 1 && values.notifyBy.includes('Email')
                          }
                        />
                        <Checkbox
                          name="notifyBy"
                          label={
                            <StyledLabel color={color} fontFamily={titleFontFamily}>
                              {intl.formatMessage({ id: 'general.text' })}
                            </StyledLabel>
                          }
                          value="Text"
                          disabled={
                            values.notifyBy.length === 1 && values.notifyBy.includes('Text')
                          }
                        />
                      </CheckBoxContainer>
                      {values.notifyBy.includes('Text') && (
                        <Warning>
                          <FormattedMessage
                            id="talentCommunity.warning"
                            defaultMessage="Standard message & data rates may apply"
                          />
                        </Warning>
                      )}
                    </div>
                    <JoinButton
                      id="talent-community-join-button"
                      type="submit"
                      color={getAsset(`${joinButtonPath}.backgroundColor`).hex}
                      disabled={invalid}
                    >
                      <FontStyled
                        id="talent-community-join-button-font"
                        fontFamily={getAsset(`${joinButtonPath}.font.fontFamily`, false).name}
                        size={font && font.fontSize}
                        color={getAsset(`${joinButtonPath}.font.fontColor`).hex}
                      >
                        <FormattedMessage
                          id="talentCommunity.joinNowButton"
                          defaultMessage="Join Now"
                        />
                      </FontStyled>
                    </JoinButton>
                    <FieldsWrapper color={color}>
                      <section style={{ textAlign: 'center' }}>
                        <FontStyled
                          id="talent-community-disclaimer"
                          color={color}
                          fontFamily={titleFontFamily}
                        >
                          <span style={{ fontSize: '10px' }}>
                            By selecting your communications preferences and clicking “Join Now” you
                            agree and consent via your electronic signature to receive calls at the
                            number(s) you provided, which may deliver autodialed, live and/or
                            prerecorded messages and/or text messages by or on behalf of{' '}
                            {clientName}. You agree that calls and text messages may include, but
                            are not limited to, promotional, advertising, and/or marketing content
                            regarding {clientName}’s goods, services, and job openings. You
                            acknowledge that message and data rates may apply. Your consent is not a
                            condition of {clientName} providing goods or services to you or
                            considering your application.{' '}
                          </span>
                        </FontStyled>
                      </section>
                    </FieldsWrapper>
                  </form>
                </div>
              )
            }}
          />
        </div>
        <NotificationDialog
          id="talent-community-notification"
          color={getAsset(`${joinButtonPath}.backgroundColor`).hex}
          fontFamily={getAsset(`${joinButtonPath}.font.fontFamily`, false).name}
          fontColor={getAsset(`${joinButtonPath}.font.fontColor`).hex}
          onClose={this.onCancel}
          open={modal}
          title={
            submitError
              ? intl.formatMessage({ id: 'talentCommunity.oops' })
              : intl.formatMessage({ id: 'talentCommunity.success' })
          }
        >
          <div>
            {submitError ? (
              <div>
                <p>{submitError.map((error) => error.fieldErrorMessage).join('\n')}</p>
              </div>
            ) : (
              <div>
                <p>
                  <FormattedMessage
                    id="talentCommunity.successMessage"
                    defaultMessage="You have successfully joined our talent community. Stay tuned for career updates."
                  />
                </p>
              </div>
            )}
          </div>
        </NotificationDialog>
      </div>
    )
  }
}

export default injectIntl(withStyles(styles)(JoinTalentCommunityForm))
