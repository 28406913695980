import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import getFromObject from 'lodash/get'
import { withStyles } from '@material-ui/core/styles'
import Slider from 'react-slick'

import { bindActionCreators } from 'redux'
import { fetchAsset, reserveAssetKey } from '../../redux/reducers/marketingAssetsCollection'
import { getAssetsFile } from '../../static/marketingAssetsServices'

const SectionTitle = styled.h1`
  font-family: ${(font) => font.fontFamily}, sans-serif;
  font-size: ${(font) => (font.size ? `${font.size}px` : 'inherit')};
  color: ${(font) => font.color};
  text-align: center;
  padding: 20px;
  margin: 0;
`

const Article = styled.article`
  max-width: 980px;
  margin: 0 auto;
  padding: 0 20px;

  h1,
  h2 {
    text-transform: uppercase;
    text-align: center;
  }
  .center {
    text-align: center;
    padding: 20px 0;
  }
`

const FontStyled = styled.div`
  display: inline-block;
  font-family: ${(font) => font.fontFamily}, sans-serif;
  font-size: ${(font) => (font.size ? `${font.size}px` : 'inherit')};
  color: ${(font) => font.color};
  margin: 0;
`

const styles = () => ({
  formControl: {
    minWidth: '100%',
  },
  select: {
    width: '100%',
    borderBottom: 'none',
    borderRadius: '4px',
    backgroundColor: 'white',
    fontStyle: 'normal',
    marginBottom: '10px',
    padding: '0 10px',
  },
})

const SectionBackground = styled.div`
  margin: 0 auto;
  width: 100%;
  background: ${(props) => props.color || 'white'};
  text-align: center;
`

const TileSection = styled.div`
  max-width: 980px;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 0 auto;
  @media screen and (max-width: 850px) {
    flex-direction: column;
  }

  img,
  iframe {
    margin: 20px 0;
  }
`

const TiledImage = styled.div`
  width: ${(p) => p.width};
  background: url(${(p) => p.background}) center center no-repeat;
  background-size: cover;
  height: 200px;
  margin: 20px;
  @media screen and (max-width: 850px) {
    width: 80%;
    height: 70vw;
  }
`
const TiledVideo = styled.iframe`
  @media screen and (max-width: 850px) {
    width: 80%;
    height: 70vw;
  }
`

const StandAloneSlider = styled.div`
  width: 780px;

  .slick-arrow {
    z-index: 9;
    &:before {
      color: black;
    }
  }
  img,
  iframe {
    margin: 0 0 -4px;
  }
  @media screen and (max-width: 780px) {
    width: 100%;
  }
`
const HeroSlider = styled.div`
  width: 100%;
  img,
  iframe {
    margin: 0 0 -4px;
  }
  .slick-arrow {
    z-index: 9;
    &:before {
      color: black;
    }
    &.slick-prev {
      left: 25px;
    }
    &.slick-next {
      right: 25px;
    }
  }
`

const sliderSettings = {
  arrows: window.innerWidth < 780 ? false : true,
  dots: true,
  autoplay: true,
  speed: 1000,
}

class SearchResults extends React.Component {
  getAsset = (assetPath, id = null, details = true) => {
    const { assetsCollection, fetchAsset, clientId, reserveAssetKey, styles } = this.props

    let assetArrayIndex = id !== null ? `[${id}]` : ''
    let assetId = ''
    const asset = getFromObject(styles, assetPath + assetArrayIndex, null)
    if (asset && asset.id) {
      return details ? asset.detail[asset.assetType] : asset
    } else {
      assetId = getFromObject(styles, assetPath + 'MaId' + assetArrayIndex, null)
    }

    if (assetId && assetsCollection && assetsCollection[assetId]) {
      return assetsCollection[assetId]
    } else if (assetId) {
      reserveAssetKey(assetId)
      fetchAsset(clientId, assetId)
      return {}
    }
    return {}
  }

  renderVideoMedia = (view_type, mediaList) => {
    switch (view_type) {
      case 'alone':
        return (
          <StandAloneSlider style={{ margin: '0 auto' }} id="media-stand-alon-slider">
            <Slider {...sliderSettings}>
              {mediaList &&
                mediaList.map((video, index) => (
                  <iframe
                    id="media-stand-alon-iframe"
                    key={index}
                    title={this.getAsset('media.mediaList', index).name}
                    width="752"
                    height="564"
                    src={`https://www.youtube.com/embed/${
                      this.getAsset('media.mediaList', index).videoId
                    }`}
                  />
                ))}
            </Slider>
          </StandAloneSlider>
        )
      case 'hero':
        return (
          <HeroSlider id="media-hero-slider">
            <Slider {...sliderSettings}>
              {mediaList &&
                mediaList.map((video, index) => (
                  <iframe
                    id="media-hero-slider-iframe"
                    key={index}
                    title={this.getAsset('media.mediaList', index).name}
                    style={{ position: 'absolute' }}
                    width="100%"
                    height="500px"
                    src={`https://www.youtube.com/embed/${
                      this.getAsset('media.mediaList', index).videoId
                    }?autoplay=1&rel=0showinfo=0&controls=0&mute=1`}
                    frameBorder="0"
                  />
                ))}
            </Slider>
          </HeroSlider>
        )
      case 'tile':
        return (
          <TileSection id="media-tile">
            {mediaList &&
              mediaList.map((video, index) => {
                return (
                  <TiledVideo
                    id="media-tile-iframe"
                    key={index}
                    title={this.getAsset('media.mediaList', index).name}
                    width={`${mediaList.length <= 3 ? 100 / mediaList.length - 5 : 45}%`}
                    height="200px"
                    src={`https://www.youtube.com/embed/${
                      this.getAsset('media.mediaList', index).videoId
                    }`}
                    frameBorder="0"
                  />
                )
              })}
          </TileSection>
        )
      default:
        return null
    }
  }

  renderImageMedia = (view_type, mediaList) => {
    const { clientId } = this.props
    switch (view_type) {
      case 'alone':
        return (
          <StandAloneSlider style={{ margin: '0 auto' }} id="media-stand-alon-slider-img">
            <Slider {...sliderSettings}>
              {mediaList &&
                mediaList.map((image, index) => (
                  <img
                    key={index}
                    src={getAssetsFile(clientId, this.getAsset('media.mediaList', index).fileId)}
                    alt={this.getAsset(`media.mediaList[${index}]`).alternateText}
                  />
                ))}
            </Slider>
          </StandAloneSlider>
        )
      case 'hero':
        return (
          <HeroSlider id="media-hero-slider-img">
            <Slider {...sliderSettings}>
              {mediaList &&
                mediaList.map((image, index) => (
                  <img
                    key={index}
                    width="100%"
                    src={getAssetsFile(clientId, this.getAsset('media.mediaList', index).fileId)}
                    alt={this.getAsset(`media.mediaList[${index}]`).alternateText}
                  />
                ))}
            </Slider>
          </HeroSlider>
        )
      case 'tile':
        return (
          <TileSection id="media-tile-img">
            {mediaList &&
              mediaList.map((image, index) => {
                return (
                  <TiledImage
                    key={index}
                    width={`${mediaList.length <= 3 ? 100 / mediaList.length - 5 : 45}%`}
                    background={getAssetsFile(
                      clientId,
                      this.getAsset('media.mediaList', index).fileId
                    )}
                    title={this.getAsset('media.mediaList', index).alternateText}
                  />
                )
              })}
          </TileSection>
        )
      default:
        return null
    }
  }

  render() {
    const {
      id,
      styles: {
        title,
        fontTitle,
        media: { text, fontText, mediaList, viewType, mediaType },
      },
    } = this.props

    return (
      <SectionBackground id={id} color={this.getAsset('backgroundColor').hex}>
        <header id="media-header">
          {title && (
            <SectionTitle
              id="media-header-title-font"
              fontFamily={this.getAsset('fontTitle.fontFamily', null, false).name}
              size={fontTitle && fontTitle.fontSize}
              color={this.getAsset('fontTitle.fontColor').hex}
            >
              {title}
            </SectionTitle>
          )}
        </header>
        {mediaType === 'video'
          ? this.renderVideoMedia(viewType, mediaList)
          : this.renderImageMedia(viewType, mediaList)}
        {text && (
          <Article id="media-body">
            <section id="media-text-section">
              <div className="center" id="media-text">
                <FontStyled
                  id="media-text-font"
                  dangerouslySetInnerHTML={{ __html: text }}
                  fontFamily={this.getAsset('media.fontText.fontFamily', null, false).name}
                  color={this.getAsset('media.fontText.fontColor').hex}
                  size={fontText && fontText.fontSize}
                />
              </div>
            </section>
          </Article>
        )}
      </SectionBackground>
    )
  }
}

const mapStateToProps = ({ assetsCollection }) => ({
  assetsCollection: assetsCollection.assets,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchAsset,
      reserveAssetKey,
    },
    dispatch
  )

const StyledSearchResults = withStyles(styles)(SearchResults)

export default connect(mapStateToProps, mapDispatchToProps)(StyledSearchResults)
