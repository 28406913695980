import { Analytics, Auth } from 'aws-amplify'

const defaultEvent = {
  sdkName: 'aws-amplify',
  clientSdkVersion: '3.3.26',
  appPackageName: 'AmplifyService',
  appTitle: 'recruit',
  appVersionCode: 'v1',
}

export const CHANNEL_TR_APPLY = 'TalentReef Apply'

export default class AmplifyService {
  static initialize = (identityPoolId, appId) => {
    const region = identityPoolId.split(':')[0]
    Auth.configure({
      identityPoolId,
      region,
    })
    Analytics.configure({
      disabled: false,
      autoSessionRecord: false,
      AWSPinpoint: {
        appId,
        region,
        endpointId: 'social-recruiting-applicant-ui',
        bufferSize: 1000,
        flushInterval: 5000, // 5s
        flushSize: 100,
        resendLimit: 5,
      },
    })
  }

  static sendEvent = (event) => {
    try {
      Analytics.record({ ...defaultEvent, timestamp: new Date().toISOString(), ...event })
    } catch (e) {
      console.error('Error sending Amplify event', e)
    }
  }

  static sendViewedEvent = async (eventAttributes) => {
    const applicationChannel = CHANNEL_TR_APPLY
    AmplifyService.sendEvent({
      name: 'Application Viewed',
      attributes: {
        applicationChannel,
        ...eventAttributes,
      },
    })
  }

  static extractSourceFromReferrer = (referrerUrl) => {
    try {
      const hostname = new URL(referrerUrl).hostname
      return hostname
    } catch (e) {
      return undefined
    }
  }

  static extractUtmSource = (currentUrl) => {
    const match = currentUrl.match(/[?&]utm_source=([^?&]+)/)
    return match ? match[1] : undefined
  }
}
